import React from 'react'

const RoundButton = ({icon, type, text, bgColor, textColor, loading, border = 'none', disabled, className = '', padding, ...buttonProps }) => {
  
  return <div
      {...buttonProps}
      style={{
        color: textColor,
        boxSizing: 'border-box',
        height: '40px',
        backgroundColor: disabled ? '#AAA' : bgColor,
        cursor: disabled ? 'not-allowed' : 'pointer',
        padding: padding ? padding : ''
      }}
      className={`max-w-min box-border rounded-full ${border ? border + ' p-4' : 'p-5 '}  text-xs font-semibold  flex flex-row items-center justify-center ${type !== 'confirm-delete' ? 'capitalize' : ''} ${className}`}
    >
      {icon}
      {text}
      {loading&&
        <div className="ml-2 animate-spin">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
        </div>
      }

    </div>
}

export default RoundButton