import React, { useState, useEffect } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { baseAuth } from "logic/api";
import Switch from 'react-switch';
import { TrashIcon, SaveIcon, XIcon } from '@heroicons/react/outline';
import { ShiftSettings } from "../../Shifts/ShiftSettings";
import { L3SlotDishSetting } from "../../Shifts/L3SlotDishSetting";
import { MINUTES_IN_DAY } from "logic/defaults";
import { ActiveSwitch } from "../ActiveSwitch";

const ShiftModal  = ({ t, shift, setShiftToEdit, saveShift, setShiftToRemove, type, l3DishCount, setL3DishCount}) => {
  const [shiftDraft, setShiftDraft] = useState(shift ? { ...shift } : null);
  const [l3DishCountDraft, setL3DishCountDraft] = useState({ ...l3DishCount });
  const [step, setStep] = useState(1);

  const changeActiveState = () => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      active: !prevShiftDraft.active,
    }});
  };

  const changeName = (name) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      name,
    }});
  };

  const changeStartMinute = (start_minute) => {
    setShiftDraft((prevShiftDraft) => {
      const maxSlots = Math.floor((MINUTES_IN_DAY - start_minute) / prevShiftDraft.slot_duration_minutes);
      const slots_quantity = Math.min(prevShiftDraft.slots_quantity, maxSlots);
    
      return {
      ...prevShiftDraft,
      start_minute,
      slots_quantity,
    }});
  };

  const changeSlotDuration = (slot_duration_minutes) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slot_duration_minutes,
    }});
  };

  const changeSlotsQuantity = (slots_quantity) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slots_quantity,
    }});
  };

  const changeDishesInParallel = (dishes_in_parallel) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      dishes_in_parallel,
    }});
  };

  const saveL3DishCountDraft = async () => {
		const body = {"l3_info": l3DishCountDraft.l3}
		await baseAuth.post(`/restaurant/update_l3_dish_count`, body).then((response) => {
			const l3DishCount = response.data.shifts
			setL3DishCount(l3DishCount)
    });
  };

  const handleNextStep = () => {
    if (step == 1) {
      saveShift(shiftDraft, false)
    } else {
      saveL3DishCountDraft()
      setStep(1)
    }
  };

  const checkDisableNextStep = () => {
    if (step == 1) {
      return !shiftDraft.slot_duration_minutes
        || !shiftDraft.slots_quantity
        || !shiftDraft.dishes_in_parallel
        || !shiftDraft.name
        || !shiftDraft.slots_quantity
        || shiftDraft.start_minute + (shiftDraft.slots_quantity * shiftDraft.slot_duration_minutes) > MINUTES_IN_DAY;
    }

    return false;
  };
  
  return (
    <div style={{ zIndex: 99 }} className="fixed top-0 left-0 right-0 bottom-0 items-center">
      <div style={{display: "flex", width: "50%", top: "5%", left: "25%", zIndex: 50, flexDirection: "column", position: "relative", alignItems: "center", overflow: "hidden", backgroundColor: "white", borderRadius: 10, padding: "30px 30px"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
          <div style={{ fontSize: "14px", fontWeight: 700}}>{type == 0 ? "Editar Turno" : "Crear Turno"}</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "220px", justifyContent: 'space-between', display: 'flex'}}>
              <ActiveSwitch checked={shiftDraft.active}/>
              <button style={{margin: "0 15px"}} onClick={() => {setShiftToEdit(null)}}><XIcon className={`w-[25px] text-gray-700 cursor-pointer`}/></button>
            </div>
        </div>
        <div style={{marginTop: "20px", width: "100%"}}>
          <div style={{display: "flex", flexDirection: "row", width: "100%", fontSize: "13px", alignItems: "center", fontWeight:  550}}>
            {step == 1 &&
            <div style={{color: step > 0 ? '#5FB894' : '#CCC', cursor: type == 0 ? 'pointer' : 'auto'}}>Configuración del turno</div>}
            {step == 2 &&
            <div style={{color: '#5FB894', cursor: type == 0 ? 'pointer' : 'auto'}}>Configuración de platos</div>}
          </div>
          <div style={{marginTop: "10px", width: "100%", height: "2px", backgroundColor: "#5FB894"}}></div>
        </div>
        <div style={{marginTop: "20px", display: "flex", height: "400px", width: '100%'}}>
        {step == 1 && <ShiftSettings shiftDraft={shiftDraft} changeName={changeName} changeStartMinute={changeStartMinute} changeSlotDuration={changeSlotDuration} changeSlotsQuantity={changeSlotsQuantity} changeDishesInParallel={changeDishesInParallel} setStep={setStep}/>}
        {step == 2 && <L3SlotDishSetting setL3DishCountDraft={setL3DishCountDraft} l3DishCountDraft={l3DishCountDraft} />}
        </div>
        <div style={{marginTop: "50px", width: "610px", height: "2px", background: '#CCC'}}></div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '25px', width: "100%", justifyContent: type == 0 ? 'space-between' : 'flex-end'}}>
        {type == 0 && 
        <button
          className={"rounded-full cursor-pointer border-2 border-gray-400 py-2 px-4 m-2 bg-white text-xs text-gray-500 font-bold"}
          onClick={() => setShiftToRemove(shift)}><TrashIcon className={`w-[20px] text-gray-700 cursor-pointer`}
          /></button>}
        <div>
          <button
            disabled={checkDisableNextStep()}
            style={{width: '175px', backgroundColor: checkDisableNextStep() ? 'gray' : '#5FB894'}}
            className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
            onClick={() => handleNextStep()}>{step == 1 ? type == 1 ? "Crear" : "Guardar" : "Guardar y continuar"}</button>
          </div>
        </div>
      </div>
      <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0  h-full fixed bg-black opacity-50'></div>
    </div>
  )
}

export default ShiftModal 