import React, { useState, useContext, useEffect } from 'react';
import DateRangeSelect from 'components/yumminn/DateRangeSelect';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { TicketsContainer } from './TicketsContainers';
import { useTickets } from './useTickets';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { EventsTable } from './EventsTable';
import { Popup } from 'common/Popup';
import { ColumnContainer } from 'pages/components/ExampleContainers';
import { EventSpecs } from './EventSpecs';
import { TicketSpecs } from './TicketSpecs';
import { Footer } from './EventsStyles';
import { saveEventOrTicket } from './EventApi';
import { Context as AuthContext } from '../../data/authContext';
import { CustomProgressBar } from "common/CustomProgressBar";
import { apiAuth } from 'logic/api';
import { SeeAtWebAppPopup } from './SeeAtWebAppPopup'
import { TabSelector } from 'common/TabSelector';

export const TABS = ["Details", "Date and hour"];

export const CreateEvents = () => {
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();

  const {
    eventTickets,
    eventsList,
    ticketsStock,
    isLoading,
    mutate,
  } = useTickets({page: 1, event:null, search:[]});

  const NEW_EVENT = {name: "", location: "", description: "", image: null, imageFile: null, event_dates: [], recurring_event_dates: [], active: true, event_dates_to_delete: []};
  const NEW_TICKET = {name: "", price_cents: "", description: "", image: null, imageFile: null, order: 0, event: "", stock: null, active: true};
  const [loading, setLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [popup, setPopup] = useState(null);
	const [eventToEdit, setEventToEdit] = useState(null);
  const [ticketToEdit, setTicketToEdit] = useState(null)
  const [eventDraft, setEventDraft] = useState(NEW_EVENT);
  const [ticketDraft, setTicketDraft] = useState(NEW_TICKET);
  const [QR, setQR] = useState(null)
  const [tab, setTab] = useState(TABS[0]);
  const [loadingQR, setLoadingQR] = useState(false);

  const restaurant = selectedRestaurant && selectedRestaurant[0].value

  const close = () => {
    setEventToEdit(null);
    setTicketToEdit(null);
    setEventDraft(NEW_EVENT);
    setTicketDraft(NEW_TICKET);
    setPopup(null);
  }

  const editEvent = (object) => {
    setEventDraft((prevObj) => ({ ...prevObj, ...object }));
  };

  const editTicket = (object) => {
    setTicketDraft((prevObj) => {
      const updatedObj = { ...prevObj, ...object };
      if (
        "stock" in object &&
        object.stock === prevObj.stock
      ) {
        delete updatedObj.stock;
      }
      return updatedObj;
    })
  };

  const getQrFromTables = (tables) => {
    if (!tables || tables.length === 0) return null; 
  
    // Find the first table whose sector has show_events_button as true and qr not null
    const tableWithEventsAndQr = tables.find(
      table => table.qr !== null && table.sector?.show_events_button === true
    );
  
    return tableWithEventsAndQr ? tableWithEventsAndQr?.qr : tables.find((table) => table.qr !== null)?.qr;

  };

  const getQr = async (restaurant, controller) => {

    if(!restaurant[0] || restaurant[0]?.show_events_page === null || restaurant[0]?.show_events_page === false){
      setLoadingQR(false)
      return;
    }

    const restaurantId = restaurant[0]?.value
    try {
      const res_obj = await apiAuth.get(
        `/tables/?restaurant=${restaurantId}`, { signal: controller.signal }
      );
      if (res_obj.data){
        const res_data = res_obj.data.results;
        
        if(res_data){
          let _array = [];
          res_data && res_data.forEach((item) => {
            item.isChecked = false;
            _array.push(item);
          });

          const tables = (_array);

          const qr = getQrFromTables(tables)

          if(qr){
            setQR(qr)
            setLoadingQR(false)
          }
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.log(`error`, error);
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    if (mounted){
      setLoadingQR(true)
      const restaurant = selectedRestaurant 
      getQr(restaurant, controller)
    }

    return () => {
      mounted = false;
      controller.abort(); 
    };
  }, [selectedRestaurant])

  useEffect(() => {
    if(eventToEdit) setPopup(1)
    if(ticketToEdit) setPopup(2)
  }, [eventToEdit, ticketToEdit])

  const saveEvent = async(eventOrTicket) => {
    if (!selectedRestaurant) return;

    setLoading(true);
  
    try {
      await saveEventOrTicket({
        restaurant: restaurant,
        eventOrTicket: eventOrTicket,
        eventToEdit: eventToEdit,
        ticketToEdit: ticketToEdit,
        object: eventOrTicket === 'EVENT' ? eventDraft : ticketDraft, 
        setSelectedEventId: setSelectedEventId,
        t: t
      })

      mutate();
      if(!ticketToEdit && !eventToEdit){
        setLoading(false);
        close();
      } else {
        setLoading(false);
        close()
      }
      

    } catch (error) {
      setLoading(false);
      console.log("🚀 saveEvent error:", error);
    }
  }

  const handleSeeAtWebApp = () => {
    if(QR) setPopup(3)
  }

  const next = () => {
    setTab('Date and hour');
  };


  const disableTab = tab => {
    return !(eventDraft.name && eventDraft.location);
  };

  const openCreateEventModal = () => {
    setPopup(1); 
    setTab(TABS[0]);
  }

  return (
    <TicketsContainer>
      <MenuHeader title={"events"} route_group={'event_tickets'}/>
      <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
        <div style={{marginBottom: "20px"}} className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
          <RestaurantSelect />
          <DateRangeSelect hourFormat={false} defaultRange='this_month'/>
        </div>
        <div style={{marginBottom: "20px"}} className='flex flex-col items-end justify-center w-full'>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={() => handleSeeAtWebApp()}
            label='see_in_webapp'
            className = 'mt-6'
            disabled={isLoading||loading||loadingQR}
            loading={isLoading||loading||loadingQR}
          />
        </div>
      </div>
      <div className='w-fit px-7'>
        <Button 
          size={BUTTON_SIZES.LG}
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => openCreateEventModal()}
          label='Create_event'
          className = 'mt-6'
          disabled={isLoading||loading}
          loading={isLoading||loading}
        />
      </div>
      <div className="panels">
        <EventsTable
          eventsList={eventsList}
          selectedEventId={selectedEventId}
          setSelectedEventId={setSelectedEventId}
          isLoading={isLoading||loading}
          setEventToEdit={setEventToEdit}
          setTicketToEdit={setTicketToEdit}
          setEventDraft={setEventDraft}
          mutate={mutate}
          setPopup={setPopup}
          eventTickets={eventTickets}
          setTicketDraft={setTicketDraft}
          setTab={setTab}
          ticketsStock={ticketsStock}
        />
      </div>
      <Popup isOpen={popup === 1} close={close}>
        <ColumnContainer>
          <h3>{t(eventToEdit ? `edit_event` : `create_event`)}</h3>
          <TabSelector
            name={"edit_event"}
            value={tab}
            options={TABS}
            onChange={setTab}
            disableTab={disableTab}
          />
          <EventSpecs eventDraft={eventDraft} editEvent={editEvent} tab={tab} eventToEdit={eventToEdit}/>
          <hr></hr>
        </ColumnContainer>
        <Footer visualize={true}>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={close}
            label='cancel'
            className = 'mt-6'
            disabled={isLoading||loading}
            loading={isLoading||loading}
          />
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={()=> tab === 'Details' ? next() : saveEvent('EVENT')}
            label={tab === 'Details' ? 'next' : 'save'}
            className = 'mt-6 p-2'
            disabled={tab === 'Details' ? !(eventDraft.location && eventDraft.name) : (isLoading || (eventDraft.event_dates.length === 0 && eventDraft.recurring_event_dates.length === 0))}
            loading={isLoading||loading}
          />
        </Footer>
        {loading && !eventToEdit &&
          <div className="absolute top-0 left-0 right-0 bottom-0 flex">
            <div className="flex flex-col gap-6 p-10 m-auto overflow-hidden bg-white rounded-3xl text-center shadow-xl z-99999999999999">
              <h4 className="text-xl font-semibold w-full text-center flex justify-center">
                {t(`creating_event`)}
              </h4>
              <CustomProgressBar estimatedSeconds={5}/>
              <i className="max-w-xs">
               {t(`do_not_forget_to_add_tickets`)}😉
              </i>
            </div>
          </div>
        }
      </Popup>
      <Popup isOpen={popup === 2} close={close}>
      <ColumnContainer>
          <h3>{t(ticketToEdit ? `edit_ticket` : `create_ticket`)}</h3>
          <hr></hr>
          <TicketSpecs ticketDraft={ticketDraft} editTicket={editTicket} ticketToEdit={ticketToEdit}/>
          <hr></hr>
        </ColumnContainer>
        <Footer>
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={close}
            label='cancel'
            className = 'mt-6'
            disabled={isLoading||loading}
            loading={isLoading||loading}
          />
          <Button 
            size={BUTTON_SIZES.LG}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={()=> saveEvent('TICKET')}
            label='save'
            className = 'mt-6 p-2'
            disabled={isLoading||loading}
            loading={isLoading||loading}
          />
          {loading && !ticketToEdit &&
          <div className="z-9999 absolute top-0 left-0 right-0 bottom-0 flex">
            <div className="flex flex-col gap-6 p-10 m-auto overflow-hidden bg-white rounded-3xl text-center shadow-xl">
              <h4 className="text-xl font-semibold w-full text-center flex justify-center">
                {t(`creating_ticket`)}
              </h4>
              <CustomProgressBar estimatedSeconds={5}/>
              <i className="max-w-xs">
               {t(`wait_some_seconds`)}...
              </i>
            </div>
          </div>
        }
        </Footer>
      </Popup>
      <SeeAtWebAppPopup isOpen={popup === 3} confirm={close} qr={QR} />
    </TicketsContainer>
  );
};

