import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Spinner from "common/Spinner";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import TopProductsTable from "./TopProductsComponents/TopProductsTable";
import { thousandsSeparator, toDecimalString } from 'logic/functions';
import { TabSelector } from "common/TabSelector";
import { TopProductCard } from "./TopProductsComponents/TopProductCard";
import { Icon, IconType } from "common/Icon";
import { Button, BUTTON_VARIANTS } from "common/Button";
import { baseAuth } from "logic/api";

export const TopProducts = () => {
	const {
		state: { topProducts, topModifiers, selectedProductCategories, selectedRestaurant, selectedDate, selectedDateTo, selectedTables },
		setTopProducts,
		setTopModifiers,
		setSelectedProductCategories,
	} = React.useContext(AuthContext);

	const { t } = useTranslation();
	const [soldProductsData, setSoldProductsData] = useState([]);
	const [notSoldProductsData, setNotSoldProductsData] = useState([]);
	const [productsDataToShow, setProductsDataToShow] = useState([]);
	const [modifiersDataToShow, setModifiersDataToShow] = useState([]);
	const [loadingExport, setLoadingExport] = useState(false);
	const [tab, setTab] = useState('products');
	const soldRate = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => pr.quantity > 0).length / productsDataToShow.length) * 100) : 0;
	const productsWithImage = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => !!pr.image).length / productsDataToShow.length) * 100) : 0;
	const productsWithDescription = productsDataToShow.length > 0 ? Math.round((productsDataToShow.filter(pr => !!pr.description || !!pr.tpv_description).length / productsDataToShow.length) * 100) : 0;
	const catalogScore = (((soldRate) + (productsWithImage * 1.3) + (productsWithDescription * 0.7)) / 30);

	useEffect(() => {
		if (!topProducts) return;
		setSoldProductsData(topProducts.sold_products_data);
		setNotSoldProductsData(topProducts.not_sold_products_data);
		filterProductData(topProducts);

		topModifiers && setModifiersDataToShow([...topModifiers.sold_modifiers_data, ...topModifiers.not_sold_modifiers_data]);
	}, [topProducts, selectedProductCategories, topModifiers])

	const filterProductData = (topProducts) => {
		if (selectedProductCategories) {
			const productCategoryIds = selectedProductCategories.map(r_o => r_o.id)
			const soldProductsFiltered = [...topProducts.sold_products_data].filter(pr => productCategoryIds.includes(pr.category))
			const notSoldProductsFiltered = [...topProducts.not_sold_products_data].filter(ns_pr => productCategoryIds.includes(ns_pr.category))
			if (productCategoryIds.length > 0) {
				const resultingArray = soldProductsFiltered.concat(notSoldProductsFiltered)
				setProductsDataToShow(resultingArray)
			} else {
				const resultingArray = soldProductsData.concat(notSoldProductsData)
				setProductsDataToShow(resultingArray)
			}
		}
	};

	const exportItems = () => {
		setLoadingExport(true);
		let startDate = new Date(selectedDate);
		startDate.setHours( 0,0,0,0 );
		startDate = startDate.getTime();
		let endDate = new Date(selectedDateTo);
		endDate.setHours( 23,59,59,0 );
		endDate = endDate.getTime();
	
		const restaurants = selectedRestaurant.reduce((str, restaurant) => str += `restaurant=${restaurant.value}&`, '');
		let endpoint = `/restaurant/top-products-export?${restaurants}from=${startDate}&to=${endDate}&item-type=${tab}`
		if (selectedTables.length > 0) {
		  const tables = selectedTables.reduce((str, table) => str += `table=${table.id}&`, '');
		  endpoint += `&${tables}`
		}
		baseAuth.get(endpoint, {
			responseType: "blob",
		  }).then((response) => {
			if (response.status !== 200) {
				throw new Error(`Error: ${response.status}`);
			}
			return response.data;
		})
		.then((data) => {
			const url = window.URL.createObjectURL(data);
		
			// Trigger the file download
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${tab}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		
			// Revoke the object URL to free up memory
			window.URL.revokeObjectURL(url);
		})
		.catch((error) => {
			console.error("Download error:", error);
		}).finally(() => setLoadingExport(false));
	};
	
	
	const productsQuantitySum = productsDataToShow.reduce((total, obj) => obj.quantity + total,0)
	const productsAmountSum = productsDataToShow.reduce((total, obj) => (obj.tpv_price_cents * obj.quantity) + total,0)

	const modifiersQuantitySum = modifiersDataToShow.reduce((total, obj) => obj.quantity + total,0)
	const modifiersAmountSum = modifiersDataToShow.reduce((total, obj) => (obj.tpv_price_cents * obj.quantity) + total,0)
	
	if (!topProducts || !productsDataToShow) return (
		<div className={`flex-auto flex flex-col items-center w-full bg-gray-100 px-8`}>
			<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
				<Spinner>{t("Loading")}</Spinner>
			</div>
			<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
		</div>
	);

	return (
		<div className={`flex-auto flex flex-col items-center w-full bg-gray-100 px-8`}>
			<div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(13rem, 1fr))', gridAutoRows: '1fr', gap: "1rem", width: '100%', marginBottom: "1rem"}}>
				<TopProductCard
					value={thousandsSeparator(productsDataToShow.length)}
					iconType={IconType.EYE}
					name='visible_products'
					isLow={productsDataToShow.length === 0}
				/>
				<TopProductCard
					value={productsWithImage + '%'}
					iconType={IconType.IMAGE}
					name='visible_products_with_image'
					isLow={productsWithImage < 10}
				/>
				<TopProductCard
					value={productsWithDescription + '%'}
					iconType={IconType.PARAGRAPH}
					name='visible_products_with_description'
					isLow={productsWithDescription < 10}
				/>
				<TopProductCard
					value={thousandsSeparator(productsDataToShow.filter(pr => pr.quantity > 0).length)}
					iconType={IconType.CUBE}
					name='unique_products_sold'
				/>
				<TopProductCard
					value={soldRate + '%'}
					iconType={IconType.CIRCLE_CHECK}
					name='visible_products_sold'
				/>
				<TopProductCard
					value={thousandsSeparator(productsQuantitySum)}
					iconType={IconType.CIRCLE_SIGMA}
					name='total_products_sold'
				/>
				<TopProductCard
					value={thousandsSeparator([...new Set(productsDataToShow.filter(pr => pr.highlighted).map(item => item.tpv_id))].length)}
					iconType={IconType.FLAG}
					name='highlighted_products'
				/>
				<TopProductCard
					value={
						<>
							<b className={catalogScore <= 3 ? 'low' : ''}>
								{toDecimalString(catalogScore)}
							</b>
							/10
						</>
					}
					iconType={IconType.STAR}
					name='average_score'
					info={
            <Trans
              i18nKey='top_products_score_info'
              defaults='Se calcula según el % de productos visibles:<br/>· Con imagen (x1,3)<br/>· Vendidos (x1)<br/>· Con descripción (x0,7)'
              components={{ br: <br/>}}
            />
					}
				/>
			</div>
			<div className="flex w-full gap-5">
				<TabSelector
					options={['products', 'modifiers']}
					value={tab}
					onChange={setTab}
					breadcrumb={false}
				/>
				<Button
					label={<span className="w-max">{t("Export_Csv")}</span>}
					leftIcon={<Icon type={IconType.DOWNLOAD}/>}
					onClick={exportItems}
					loading={loadingExport}
					variant={BUTTON_VARIANTS.PRIMARY}
				/>
			</div>
			
			<TopProductsTable
				data={tab === 'products' ? productsDataToShow : modifiersDataToShow}
				itemsQuantitySum={tab === 'products' ? productsQuantitySum : modifiersQuantitySum}
				itemsAmountSum={tab === 'products' ? productsAmountSum : modifiersAmountSum}
			/>
		</div>
	);
};

export default TopProducts;
