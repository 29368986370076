import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

export const GlobalToastContainer = () => {

  return (
    <div translate="no" className="modal-toastify notranslate" id="modal-toastify" style={{zIndex: 99999}}>
      <div id="modal-toastify-body">
        <ToastContainer
            position="top-right"
            hideProgressBar
            autoClose={2000}
            transition={Slide}
            closeOnClick
        />
      </div>
    </div>
  )
}