import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const SettingsTab = ({ type }) => {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("yumminn-user"));

  return (
    <div className='flex flex-col py-4'>
      <div
        className={'flex flex-row text-[14px] text-secundario font-bold py-3'}
      >
        {
          type === 'settings' &&
          <>
            <NavLink
              exact={true}
              activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
              to='/settings'
              className='border-b-2 border-secundario pr-8'
            >
              {t('General')}
            </NavLink>
            <NavLink
              activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
              to='/settings/description'
              className='border-b-2 border-secundario pr-8'
            >
              {t('Description')}
            </NavLink>
          </>
        }
        {
          type === 'tables' &&
          <>
            {/*<NavLink
              exact={true}
              activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
              to='/settings/tables'
              className='border-b-2 border-secundario pr-8'
            >
              {t('Table_statuses')}
            </NavLink>*/}
            {user.is_superuser &&
              <NavLink
              activeStyle={{ color: '#5FB894', borderBottomColor: '#5FB894' }}
              to='/settings/tables/sectors'
              className='border-b-2 border-secundario pr-8'
            >
              {t('EditTablesSectors')}
            </NavLink>
            }
          </> 
        }        
      </div>
    </div>
  );
};

export default SettingsTab;
