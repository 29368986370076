import React,{ useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveIcon } from '@heroicons/react/outline';
import * as _ from 'lodash';
import { apiAuth } from 'logic/api';
import { EyeIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import QRCode from 'react-qr-code';
import logo from 'img/logo.png';
import CheckBox from '../CheckBox';
import { saveAs } from 'file-saver';
import { CSVLink } from "react-csv";
import { useState } from 'react';
import Spinner from 'common/Spinner';
import { Context } from 'data/authContext';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';

const QRCodesPage = () => {
  const { t } = useTranslation();
  
	const {
		state: { selectedRestaurant, selectedRestaurantId },
	} = React.useContext(Context);
  const [sectores, setSectores] = useState([]);
  const [tableClicked, setTableClicked] = useState(null);
  const [tablesChecked, setTablesChecked] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [tables, setTables] = useState([]);
  const [qrUrl, setQrUrl] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(()=>{
    setTables([]);
    setAllChecked(false);
  },[selectedRestaurantId])

  const getData = async () => {
    try {
      const res_obj = await apiAuth.get(
        `/tables/?restaurant=${selectedRestaurantId}`
      );
      if (res_obj.data){
        const res_data = res_obj.data.results;
        
        if(res_data){
          let _array = [];
          res_data && res_data.map((item) => {
            item.isChecked = false;
            _array.push(item);
          });

          setTables(_array);
          setSectores(res_data);
        }
      }
    } catch (error) {
      //toast.error(sayError(error.response.data));
      console.log(`error`, error);
    }
  };

  useEffect(() => {
    if (selectedRestaurantId) {
      getData();
    }
  }, [selectedRestaurantId]);

  const handleClickEye = ({number, name, qr}) => {
    setTableClicked({number, name, qr});
    const URL = buildQR(qr, number);
    setQrUrl(URL);
    setShowModal(true);
  };

  const handleDownloadQr = (type) => {
    setLoadingExport(true)
    if (tablesChecked.length === 0) {
      setMessage(true);
      setLoadingExport(false)
    } else {
      const tables = tablesChecked.map((t) => t.number);
      apiAuth.post(
        `/restaurants/${selectedRestaurantId}/tables_qr/?type=${type}`,
        { tables },
        {
          responseType: 'blob'
        }
        
      ).then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.status}`);
        }
        return response.data;
      }).then((data) => {
        const url = window.URL.createObjectURL(data);
      
        // Trigger the file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `qr_codes.${type == 'pdf' ? 'pdf' : 'zip'}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      
        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
      }).catch((error) => {
        console.error("Download error:", error);
      }).finally(() => setLoadingExport(false));
    }
  };

  const handleAllChecked = (event) => {
    setAllChecked(!allChecked);
    const _tables = tables.map((item) => {
      item.isChecked = event.target.checked;
      return item;
    });
    setMessage(false);
    setTables(_tables);
    if (event.target.checked) {
      setTablesChecked(_tables);
    } else {
      setTablesChecked([]);
    }
  };

  const handleCheckChieldElement = (table) => {
    setMessage(false);
    const isTableChecked = tablesChecked.find(
      (item) => item.table === table.id
    );
    if (!isTableChecked) {
      const _tables = tables.map((item) => {
        if (item.id === table.id) {
          item.isChecked = true;
        }
        return item;
      });
      setTables(_tables);
      setTablesChecked([
        ...tablesChecked,
        {
          table: table.id,
          sector: table.sector,
          number: table.number,
          isChecked: true
        }
      ]);
    } else {
      const _tables = tables.map((item) => {
        if (item.id === table.id) {
          item.isChecked = false;
        }
        return item;
      });
      setTables(_tables);
      setTablesChecked(tablesChecked.filter((item) => item.table !== table.id));
    }
  };

  const buildQR = (qr, number) => {
    if (qr){
      return `${process.env.REACT_APP_URL}/${qr}`
    } else {
      return `${process.env.REACT_APP_URL}/home/?id=${selectedRestaurantId}&table=${number}`
    }
  }

  let headers = [
    { label: "URL", key: "qr" },
    { label: "Table", key: "table" },
    { label: "Sector", key: "sector" },
    { label: "Restaurant", key: "restaurant" },
  ];

  const handleDownloadCsv = () => {
    let tablesSelected = 0
    let data = tables.map((item)=>{
      if(item.isChecked){
        let suffix = item.qr ? item.qr : `home/?id=${selectedRestaurantId}&table=${item.number}`;
        return {
          'qr': `${process.env.REACT_APP_URL}/${suffix}`,
          'table':item.name,
          'sector':item.sector.name,
          'restaurant':selectedRestaurant[0].label
        };
      }
      tablesSelected+=1
      return {};
    });
    
    if(tables.length==tablesSelected){
      data = tables.map((item)=>{
          return {
            'qr':'https://webapp.yumminn.com/'+item.qr,
            'table':item.name,
            'sector':item.sector.name,
            'restaurant':selectedRestaurant[0].label
          };
      });
    }
    
    setCsvData(data);
  }


  useEffect(() => {
    if(tables.length > 0 && selectedRestaurantId){
      handleDownloadCsv();
    }
  }, [tables])
  

  if (!sectores) return null;
  
  if (sectores) {
    
    return (
      <>
        
        {showModal && (
          <div>
            <div className='z-40 overflow-auto w-3/6 h-full fixed'>
              <div className='z-50 relative p-3 mx-auto my-0 max-w-full '>
                <div className='flex flex-col overflow-hidden bg-white'>
                  <button
                    onClick={() => setShowModal(false)}
                    className='fill-current h-6 w-6 absolute right-0 top-0 m-10 font-6xl font-bold'
                  >
                    &times;
                  </button>

                  <div
                    className={`flex flex-col py-3 mt-12 w-full text-center items-center`}
                  >
                    <div className={'pb-4'}>
                      <img src={logo} alt='logo' />
                    </div>
                    <div className='mt-5 text-center'>
                      <QRCode
                        size={280}
                        value={qrUrl}
                      />
                    </div>
                    <div className={'py-4'}>{selectedRestaurant[0].label}</div>
                    <div className={'pb-4'}>Mesa {tableClicked.name}</div>

                    <div className=' mt-4 -ml-2 grid grid-cols-2 gap-6 mb-3'></div>
                  </div>
                </div>
              </div>
              <div className='z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50'></div>
            </div>
          </div>
        )}
        <div className=''>
          <div className='mb-5 w-full flex justify-between'>
            {csvData.length>0&&
            <div className='flex justify-between w-full'>
              <div className='flex gap-5'>
                <CSVLink data={csvData} headers={headers} separator={";"} filename={(selectedRestaurant[0].label+"_"+(new Date().toJSON().slice(0,10))+"_"+new Date().toLocaleTimeString()).replace(/ /g,"_")+'.csv'}>
                  <Button
                    disabled={loadingExport}
                    label="Export_Csv"
                    leftIcon={<Icon type={IconType.DOWNLOAD}/>}
                    variant={BUTTON_VARIANTS.SECONDARY}
                  />
                </CSVLink>
                <Button
                label="export_svg_qr_codes"
                leftIcon={<Icon type={IconType.DOWNLOAD}/>}
                onClick={() => handleDownloadQr("svg")}
                disabled={loadingExport}
                variant={BUTTON_VARIANTS.SECONDARY}
                />
              </div>
              <Button
                label="export_pdf_qr_codes"
                onClick={() => handleDownloadQr("pdf")}
                disabled={loadingExport}
                variant={BUTTON_VARIANTS.PRIMARY}
              />
            </div>
            }
          </div>
        </div>
        {message && (
          <div className='flex justify-end mb-5 w-full pr-8'>
            {t('Selecciona una linea')}
          </div>
        )}
        <div className='bg-white grid grid-cols-5 p-5 border-b-2'>
          <div className='flex flex-row ml-4 w-full'>
            {tables.length>0&&
              <input
                checked={allChecked}
                type='checkbox'
                onClick={(e) => handleAllChecked(e)}
                className='rounded bg-gray-200 border-transparent focus:border-transparent text-primary focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 mt-1 mr-6'
              />
            }
            <div className='w-full'>{t('Seleccionar todo')}</div>
          </div>
          <div className='flex flex-row ml-4'>
            <div>{t('Sector')}</div>
          </div>
          <div>{t('Table')}</div>
          <div>{t('Number')}</div>
          <div className='-ml-4 w-1/6'>{t('preview')}</div>
        </div>
        
        <div className={'flex-col'}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className='mb-5 bg-white p-3 min-w-[420px]'
          >
            <div className='p-3'>
              {tables.length===0&&
                <div className="w-full z-9999 flex flex-col justify-center items-center">
                  <Spinner>{t('Loading')}</Spinner>
                </div>
              }
              {tables.map((table, index) => {
                return (
                  <div key={index} className='grid grid-cols-5 p-5 border-b-2'>
                    <div className='flex flex-row'>
                      <CheckBox
                        handleCheckChieldElement={() =>
                          handleCheckChieldElement(table)
                        }
                        table={table}
                        sectores={sectores}
                      />
                    </div>
                    <div>{table.sector.name}</div>
                    <div>{table.name}</div>
                    <div>{table.number}</div>
                    <div
                      className='cursor-pointer'
                      onClick={() => handleClickEye(table)}
                    >
                      <EyeIcon className='h-6 w-6' />
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </>
    );
  }
};

export default QRCodesPage;
