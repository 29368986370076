import React, { useEffect, useState, useContext, useRef } from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import {toast} from 'react-toastify';
import _ from 'lodash';
import FormError from 'common/FormError';
import {apiAuth} from 'logic/api';
import {TrashIcon} from '@heroicons/react/outline';
import useDeleteModal from 'components/hooks/useDeleteModal';
import {motion} from 'framer-motion';
import {sayError} from 'logic/defaults';
import { Context } from 'data/authContext';
import Loading from 'common/Loading';
import UpdateTablesInfoModal from '../tables_sectors/UpdateTablesInfoModal';
import { HiddenButton } from 'pages/admin/pages/Lockers/LockerContainers';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import PricePerSectorPopup from './customization/PricePerSectorPopup';
import WarningPopup from './customization/components/WarningPopup';

const TablesSectorPage = () => {
  const {t} = useTranslation();
	const {
		state: { selectedRestaurantId },
	} = useContext(Context);

  const [sectores, setSectores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUpdateInfo, setShowUpdateInfo] = useState(false);
  const [newTables, setNewTables] = useState([]);
  const [deactivatedTables, setDeactivatedTables] = useState([]);
  const [newActiveTables, setNewActiveTables] = useState([]);
  const [openSectors, setOpenSectors] = useState({});
  const [popupOpened, setPopupOpened] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false)
  const [sectorsAndPrice, setSectorsAndPrice] = useState({});
  const [loadingSectors, setLoadingSectors] = useState(false)
  const [isTpvYumminn, setIsTpvYumminn] = useState(false)

  const TPV_YUMMINN_ID = 85
  const newSectorRef = useRef(null);

  const getData = async () => {
    try {
      const res_obj = await apiAuth.get(`/restaurants/${selectedRestaurantId}`);
      const res_data = res_obj.data;
      if(res_data?.tpv?.id === TPV_YUMMINN_ID) { 
        setIsTpvYumminn(true)
      }
      const ordenados = _.orderBy(res_data?.sectors, ['name']); 
      const filtrados = _.filter(ordenados, sector => "active" in sector && sector["active"] === true);
      filtrados.forEach(sector => {
        sector["tables"] = _.filter(sector["tables"], table => "active" in table && table["active"] === true);
      });
      setSectores(filtrados);
      // Initialize open state for all sectors as true
      const initialOpenState = {};
      filtrados.forEach((sector) => {
        initialOpenState[sector.id] = true;
      });
      setOpenSectors(initialOpenState);
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const getSectorsAndPrice = async() => {
      setLoadingSectors(true);
      try {
          const { data } = await apiAuth(`/get_sectors_and_price_per_sector/${selectedRestaurantId}`, { method: 'GET' });
          setSectorsAndPrice(data);
      } catch (error) {
          console.error('Error fetching sectors and price info:', error);
      } finally {
        setLoadingSectors(false);
      }
  }
  
  useEffect(() => {
    if (!selectedRestaurantId) return;
      getData();
  }, [selectedRestaurantId]);

  const handleSubmitSector = async (e, sector) => {
    if (Array.isArray(sector)) {
      sector = sector[0]; 
    }

    try {
      if (sector.id) {
        await apiAuth.patch(`/sectors/${sector.id}/`, {...sector});
      } else {
        await apiAuth.post(`/sectors/`, {...sector});
      }
      toast.success(`${t('update_ok')}`);
      getData();
    } catch (error) {
      toast.error(sayError(error?.response?.data));
    }
  };

  const handleSubmitTable = async (e, table) => {
    try {
      if (table.id) {
        await apiAuth.patch(`/tables/${table.id}/`, {...table});
      } else {
        const newTable = {...table, permanent: true}
        await apiAuth.post(`/tables/`, {...newTable});
      }
      toast.success(`${t('update_ok')}`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleSync = async () => {
    if (isTpvYumminn) return
    setLoading(true)
    try {
      const res_sync_obj = await apiAuth.post(`/tables/sync`, {restaurant: selectedRestaurantId});
      try {
        const res_sync_data = res_sync_obj.data;
        setNewTables(res_sync_data.new_tables)
        setDeactivatedTables(res_sync_data.deactivated_tables)
        setNewActiveTables(res_sync_data.inactive_tables_now_active)
        setShowUpdateInfo(true)
      } catch {
        console.log("Error getting update info")
      }
      //toast.success(`${t('¡Sincronizado!')}`);
      getData();
    } catch (error) {
      toast.error(error.response.data ? sayError(error.response.data) : "Error al sincronizar");
    }
    setLoading(false)
  };

  const handleAddSector = () => {
    setSectores((prev) => [
      ...prev,
      {
        name: 'Untitled',
        restaurant: selectedRestaurantId,
        tables: [],
      },
    ]);
    setTimeout(() => {
      if (newSectorRef.current) {
        newSectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const handleAddTable = (e, sector) => {
    const nuevos_sectores = sectores.map((s) => {
      if(!sector || !sector.hasOwnProperty('id')) {
        setWarningPopup(true)
      }
      if (s.id !== sector.id) {
        return s;
      } else {
        return {
          ...s,
          tables: [...s.tables, {name: 'Untitled', sector: s.id}],
        };
      }
    });

    setSectores(nuevos_sectores);
  };

  const handleDeleteSector = async (sector_id) => {
    if (!sector_id) {
      getData();
      return;
    }
    try {
      await apiAuth.post(`/sectors/${sector_id}/deactivate`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleDeleteTable = async (table_id) => {
    if (!table_id) {
      getData();
      return;
    }

    try {
      await apiAuth.post(`/tables/${table_id}/deactivate`);
      getData();
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  };

  const handleChangeTable = (e, table, sector) => {
    const nombre = e.target.name.split('.')[1];

    //
    const nuevos_sectores = sectores.map((s) => {
      if (s.id !== sector.id) {
        return s;
      } else {
        const nuevas_tables = s.tables.map((t) => {
          if (t.id !== table.id) {
            return t;
          } else {
            return {...t, [nombre]: e.target.value};
          }
        });
        return {...s, tables: nuevas_tables};
      }
    });

    setSectores(nuevos_sectores);
  };

  const toggleSectorOpen = (sectorId) => {
    setOpenSectors((prevState) => ({
      ...prevState,
      [sectorId]: !prevState[sectorId], 
    }));
  };

  const openPricePerSectorPopup = async() => {
    await getSectorsAndPrice();
    setPopupOpened(true)
  }

  const tableModal = useDeleteModal(handleDeleteTable);
  const sectorModal = useDeleteModal(handleDeleteSector);

  if (!sectores) return null;

  if (sectores) {
    return (
      loading ? <Loading /> : 
      showUpdateInfo ? 
        <UpdateTablesInfoModal new_tables={newTables} deactivated_tables={deactivatedTables} new_active_tables={newActiveTables} setShowUpdateInfo={setShowUpdateInfo}/> :
      <div className={'flex flex-wrap'}>

        <div className={'flex gap-8 pt-8'}>
          <Button
            label={t("add_price_per_sector")}
            onClick={openPricePerSectorPopup}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
          <Button
            label={t("Addnewsector")}
            onClick={handleAddSector}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
        </div>

        <div className='self-end mb-8 w-full'>
          <HiddenButton id="synchronize_tables" onClick={handleSync}/>
        </div>

        {sectores.map((sector, index) => (
          <motion.div
            ref={index === sectores.length - 1 ? newSectorRef : null} // Assign the ref to the last sector (newly added one)
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            key={index}
            className='mb-5 mr-3 bg-white p-8 rounded-[20px]'
            style={{minWidth: "420px", height: "fit-content"}}
          >
            <Formik
              enableReinitialize={true}
              initialValues={sector || {}}
            >
              {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit
                }) => (
                <form onSubmit={handleSubmit} autoComplete='off'>
                  {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(updated, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                  <span className='text-[16px]'>{t('Sector')}</span>

                  <div
                    key={sector.id}
                    className='flex flex-col space-y-3 divide-y'
                  >
                    <div className='flex items-center w-full space-x-2 gap-4 items-center mb-1 mt-6'>
                      <div className='w-full flex flex-col text-[16px]'>
                        <input
                          type='text'
                          placeholder={t('Sector')}
                          className='yumminn-input-modified'
                          value={values?.name || ''}
                          name='name'
                          onChange={handleChange}
                        />
                        <FormError message={errors.values?.name}/>
                      </div>
                      <button
                        type='button'
                        onClick={() => sectorModal.openModal(sector.id)}
                      >
                        <TrashIcon className="cursor-pointer h-4 w-4 text-[#094553]"/>
                      </button>
                      <button
                        type='button'
                        onClick={(e) => handleSubmitSector(e, values)}
                        className='text-[#094553] cursor-pointer underline focus:outline-none text-[16px] font-semibold'
                      >
                        {sector?.id ? t('update') : t('save')}
                      </button>
                    </div>
                    <div className='py-6'>
                      <div 
                        className='flex justify-between items-center'
                        onClick={() => toggleSectorOpen(sector.id)} 
                      >
                        <span className='text-[16px]'>{t('Tables')}</span>
                        {openSectors[sector.id] && sector.tables.length > 0 ? (
                          <ChevronUpIcon className="w-6 h-5 text-[#094553]" />
                        ) : sector.tables.length > 0 ? (
                          <ChevronDownIcon className="w-6 h-5 text-[#094553]" />
                        ) :
                          <></>
                        }                      
                      </div>
                      {openSectors[sector.id] && !loadingSectors && sector.tables.map((table, index) => (
                        <motion.div
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          transition={{duration: 0.5}}
                          key={index}
                          className='flex items-center w-full space-x-2 pt-6 text-sm text-[16px]'
                        >
                          <input
                            type='text'
                            placeholder={t('Sector')}
                            className='yumminn-input-modified max-w-[120px]'
                            value={table.name || ''}
                            name='table.name'
                            onChange={(e) => {
                              handleChangeTable(e, table, sector);
                            }}
                          />
                          <input
                            type='text'
                            placeholder={t('Number')}
                            className='yumminn-input-modified max-w-[120px]'
                            value={table.number || ''}
                            name='table.number'
                            onChange={(e) => {
                              handleChangeTable(e, table, sector);
                            }}
                            disabled={isTpvYumminn ? false : true}
                          />
                          <button
                            type='button'
                            onClick={() => tableModal.openModal(table.id)}
                            className='mr-4'
                          >
                            <TrashIcon className="cursor-pointer h-4 w-4 text-[#094553] mr-4"/>
                          </button>
                          <button
                            type='button'
                            onClick={(e) => handleSubmitTable(e, table, sector)}
                            className='text-[#094553] cursor-pointer underline focus:outline-none text-[16px] font-semibold'
                          >
                            {table?.id ? t('update') : t('save')}
                          </button>
                        </motion.div>
                      ))}
                      <Button
                        label={t("Addnewtable")}
                        className='mt-6'
                        onClick={(e) => handleAddTable(e, sector)}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        leftIcon={<p className='pt-[1px] h-[20px] w-[20px] flex justify-center items-center text-white bg-[#094553] font-medium rounded-full hover:text-medio'>+</p>}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </motion.div>
        ))}

        <sectorModal.Modal mensaje={t('Deletesector')}/>
        <tableModal.Modal mensaje={t('Deletetable')}/>
        <PricePerSectorPopup open={popupOpened} close={() => setPopupOpened(false)} sectorsAndPrice={sectorsAndPrice} handleSubmitSector={handleSubmitSector} />
        <WarningPopup open={warningPopup} close={() => setWarningPopup(false)} />
      </div>
    );
  }
};

export default TablesSectorPage;
