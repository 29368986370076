import React, { useContext, useEffect, useState } from 'react';
import RestaurantSelect from 'components/yumminn/RestaurantSelect';
import SideBar from 'layouts/MenuContainer';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { AddButton } from 'pages/admin/pages/Lockers/LockerContainers';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { Context } from 'data/authContext';

const ServiceContainer = ({ children, title }) => {
  const { t } = useTranslation();
	const {
		state: { selectedRestaurant },
	} = useContext(Context);
  const [isTpvYumminn, setIsTpvYumminn] = useState(false)

  const isRowBased = useMediaQuery("(max-width: 760px)");
  const path = window.location.pathname;
  const isSectorsAndTablesPage = path === '/settings/tables/sectors'; 
  const isLockersPage = path === '/admin/lockers';
  const TPV_YUMMINN_ID = 85;

  useEffect(() => {
    if(selectedRestaurant && selectedRestaurant[0]?.tpv_id){
      if(selectedRestaurant[0]?.tpv_id === TPV_YUMMINN_ID){
        setIsTpvYumminn(true)
      } else {
        setIsTpvYumminn(false)
      }
    } 
  }, [selectedRestaurant])
  

  return (
    <div id="menuContainer" className={`flex flex-col bg-gray-100 h-full w-full max-w-full ${isRowBased?'flex-col':'flex-row'}`}>
      <SideBar style={{flex:' 0 1 auto',position:'fixed',overflow: 'hidden'}}/>
      <div className={`${isRowBased ? 'flex flex-col pl-1 mt-16 inset-0' : 'flex flex-col flex-1'}`}>
        
        <MenuHeader title={title} route_group={"service"}/>
        <div className={`${isRowBased && 'bg-white'} flex flex-col flex-1`} >
        <div className={`${!isSectorsAndTablesPage ? 'bg-white py-4 px-[32px] flex gap-4' : 'py-4 bg-white flex justify-between items-end px-[32px]'}`}>
          <RestaurantSelect />
            {isLockersPage &&
              <AddButton onClick={() => document.getElementById('add_locker').click()}>
                <PlusCircleIcon height={24}/>
                {t('add_locker')}
              </AddButton>
            }
            {(isSectorsAndTablesPage && !isTpvYumminn) &&
              <Button 
                size={BUTTON_SIZES.LG}
                variant={BUTTON_VARIANTS.PRIMARY}
                onClick={() => document.getElementById('synchronize_tables').click()}
                label='synchronize'
                rightIcon={<Icon type={IconType.SYNCHRONIZE} />}
              />
            }
          </div>
          <div className={`px-[32px] flex-1 ${path !== '/admin/lockers' ? 'overflow-hidden' : ''}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer;
