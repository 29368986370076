import React from 'react';
import { Link } from 'react-router-dom';
import XTextButton from 'common/XTextButton';
import { useTranslation } from 'react-i18next';
import { api } from '../../logic/api';
import { toast } from 'react-toastify';
import { Icon, IconType } from 'common/Icon';
import { useViewport } from 'data/ViewportContext';
import { Logo } from 'common/Logo';
import { TextInput } from 'common/Input';

const Forgot = () => {
  const { t } = useTranslation();
  const {isMobile, isTablet} = useViewport();
  const [submitting, setSubmitting] = React.useState(false);
  const [state, setState] = React.useState({
    email: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgot = async () => {
    if (state.email !== '') {
      setSubmitting(true);
      try {
        const res = await api.post('/password_reset/', {
          email: state.email,
        });
        if (res.status === 200){
          toast.success(`${t('forgot_email_sent')}`);
        }else{
          toast.error(`${t('no_email')}`);
        }
        // setTimeout(() => history.push("/login"), 2000);
      } catch (err) {
        toast.error(`${t('no_email')}`);
      } finally {
        setSubmitting(false);
      }
    } else {
        toast.error(`${t('no_email')}`);
    }
  };

  return (

    <div className='flex h-screen'>

      {(!isMobile && !isTablet) &&
        <div style={{textAlign: 'center'}} className='flex-1 bg-[#094553] relative justify-center items-center content-center justify-items-center text-center'>

          <Logo size={50} className="flex flex-col px-6"/>

          <div style={{ width: "100%", height: "auto" }} className='absolute top-0 right-[-50%]'>
            <Icon type={IconType.SEMI_CIRCLE_BOTTOM_PART} />
          </div>

          <div style={{ width: "100%", height: "auto" }} className='absolute bottom-0'>
            <Icon type={IconType.SEMI_CIRCLE_TOP_PART_WITH_FILLING} />
          </div>
          

        </div>
      }

      <div className='flex-1 bg-white justify-center items-center content-center justify-items-center z-10 relative overflow-hidden'>
        
          <div className={'px-2 w-11/12 lg:max-w-md md:w-3/4'}>
            <h3 style={{fontSize: 'clamp(1.125rem,2vw,1.75rem)'}} className={'font-semibold text-[#094553]'}>
              {t('forgot_password_text')}
            </h3>

            <div className='py-4 text-[#094553] lg:w-11/12' style={{fontSize: 'clamp(1rem,2vw,1.125rem)'}}>{t('forgot_password_explanation')}</div>

            <div style={{fontSize: 'clamp(1rem,2vw,1.125rem)'}} className={'font-medium text-capitalize pt-4 pb-2'}>
              {t('write_your_email')}<span style={{color: 'red'}}>*</span>
            </div>
            <TextInput
              className='mt-2 mb-8'
              name='email'
              placeholder={t('Email')}
              value={state.email || ''}
              onChange={handleChange}
            />

            <XTextButton
              disabled={submitting || state.email === ''}
              submitting={submitting}
              doClick={handleForgot}
              isMobileOrTablet={(isMobile || isTablet)}
            >
              {t('send_reset_link')}
            </XTextButton>
          </div>

          <Link to='/'>
            <div style={{fontSize: 'clamp(0.875rem,2vw,1rem)', textAlign: 'center'}} className='z-10 text-[#094553] underline mt-6 font-semibold'>{t('return_home')}</div>
          </Link>

        <div style={{width: "25%", height: "20%"}} className="absolute bottom-0 right-0 border-[8px] overflow-visible border-none rounded-br-2xl">
          <div style={{borderRadius: "0px 0px 18rem", width: (isMobile || isTablet) ? "195%" : "160%", height: (isMobile || isTablet) ? "15%" : "27%", rotate: (isMobile || isTablet) ? "-238deg" : "-230deg"}} className="bg-[#9DEECB] absolute"></div>
          <div style={{clipPath: "polygon(100% 0, 100% 100%, 0% 100%)", width: (isMobile || isTablet) ? "4rem" : "9rem", height: (isMobile || isTablet) ? "7rem": "11rem"}} className="absolute bottom-0 right-0 bg-[#094553]"></div>
        </div>

      </div>
    </div>
  );
};

export default Forgot;
