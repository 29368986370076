import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Spinner from "common/Spinner";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import orderAndPayLogo from "../../../img/o&p.svg"
import { HighlightsEmptyStateIcon } from "common/EmptyStateIcons";
import EmptyState from "common/EmptyState";
import { Button, BUTTON_VARIANTS } from "common/Button";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { ProductHighlightTable } from "./ProductHighlightComponents/ProductHighlightTable";
import { useProductHighlight } from "./useProductHighlight";
import { ProductHighlightContainer } from "./ProductHighlightComponents/ProductHighlightContainers";
import { CustomGroupModal } from "./ProductHighlightComponents/CustomGroupModal";
import { DeletePopup } from "./Modifiers/DeletePopup";

let NEW_PRODUCT_HIHGLIGHT = {
	id: null,
	relation_id: null,
	active: true,
	visibility: 1,
	visibility_schedules: [],
	primary_color: '#FFFFFF',
	secondary_color: '#FFFFFF',
	title_color: '#212121',
	products_info: [],
};

let NEW_BANNER = {
	...NEW_PRODUCT_HIHGLIGHT,
	custom_group_type: 'commercial',
};

export const ProductHighlight = () => {
	const { t } = useTranslation();

	const {
		restaurant,
		catalog,
		restaurantGroups,
		l1Groups,
		l2Groups,
		l3Groups,
		isLoading,
		isValidating,
    	saveCustomGroup,
		deleteCustomGroup,
		toggleActive,
    	changeGroupOrder,
	} = useProductHighlight()

	const [customGroupToEdit, setCustomGroupToEdit] = useState(null);
	const [customGroupToRemove, setCustomGroupToRemove] = useState(null);

	const closeEditPopup = () => setCustomGroupToEdit(null);

	if (isLoading) return (
		<ProductHighlightContainer>
			<div>
				<Spinner>{t("Loading")}</Spinner>
			</div>
		</ProductHighlightContainer>
	);

	return (
			<ProductHighlightContainer>
				<div className="buttons">
					<Button
						label='create_new_featured_product'
						leftIcon={<PlusCircleIcon/>}
						onClick={() => setCustomGroupToEdit(NEW_PRODUCT_HIHGLIGHT)}
						disabled={isValidating || isLoading}
					/>
					<Button
						label='create_advertising_banner'
						leftIcon={<PlusCircleIcon/>}
						onClick={() => setCustomGroupToEdit(NEW_BANNER)}
						variant={BUTTON_VARIANTS.SECONDARY}
						disabled={isValidating || isLoading}
					/>
				</div>
				{restaurantGroups.length > 0 &&
					<ProductHighlightTable
						changeGroupOrder={changeGroupOrder}
						title='catalog_start'
						customGroups={restaurantGroups}
						setCustomGroupToRemove={setCustomGroupToRemove}
						setCustomGroupToEdit={setCustomGroupToEdit}
						toggleActive={toggleActive}
						isValidating={isValidating}
						droppableId={`restaurant_${restaurant}`}
					/>
				}
				{l1Groups.map(([l1, name, customGroups]) =>
					<ProductHighlightTable
						changeGroupOrder={changeGroupOrder}
						title={`${t('l1_start')} (${name})`}
						customGroups={customGroups}
						setCustomGroupToRemove={setCustomGroupToRemove}
						setCustomGroupToEdit={setCustomGroupToEdit}
						toggleActive={toggleActive}
						isValidating={isValidating}
						droppableId={`l1_${l1}`}
					/>
				)}
				{l2Groups.map(([l2, name, customGroups]) =>
					<ProductHighlightTable
						changeGroupOrder={changeGroupOrder}
						title={`${t('l2_start')} (${name})`}
						customGroups={customGroups}
						setCustomGroupToRemove={setCustomGroupToRemove}
						setCustomGroupToEdit={setCustomGroupToEdit}
						toggleActive={toggleActive}
						isValidating={isValidating}
						droppableId={`l2_${l2}`}
					/>
				)}
				{l3Groups.map(([l3, name, customGroups]) =>
					<ProductHighlightTable
						changeGroupOrder={changeGroupOrder}
						title={`${t('l1_start')} (${name})`}
						customGroups={customGroups}
						setCustomGroupToRemove={setCustomGroupToRemove}
						setCustomGroupToEdit={setCustomGroupToEdit}
						toggleActive={toggleActive}
						isValidating={isValidating}
						droppableId={`l3_${l3}`}
					/>
				)}
				{!restaurantGroups.length && !l1Groups.length && !l2Groups.length && !l3Groups.length &&
					<EmptyState
						text={
							<Trans i18nKey={"increase_your_average_ticket"}>
								<span style={{fontWeight: 600}}>¡Incrementa tu ticket medio <i>destacando</i></span><br></br> tus productos!
							</Trans>
						}
						icon={<HighlightsEmptyStateIcon/>}
					/>
				}
				{!catalog?.product_categories?.length &&
					<div className=" bg-white rounded-xl h-36 flex gap-4 sm:flex-row font-semibold flex-col-reverse items-center justify-center">
						<img src={orderAndPayLogo} alt="Order and Pay"></img>
						<div style={{textAlign: 'center'}}>No se ha encontrado ningún producto para poder destacar.<br/>Debe sincronizar la carta.</div>
					</div>
				}
				{customGroupToEdit &&
					<CustomGroupModal
						customGroup={customGroupToEdit}
						catalog={catalog}
						saveCustomGroup={saveCustomGroup}
						close={closeEditPopup}
						toggleActive={toggleActive}
						isValidating={isValidating}
					/>
				}
				<DeletePopup
					isOpen={!!customGroupToRemove}
					title={t(`delete_${customGroupToRemove?.custom_group_type === 'commercial' ? 'banner' : 'custom_group'}_title`, { name: customGroupToRemove?.name || '' })}
					message={`delete_${customGroupToRemove?.custom_group_type === 'commercial' ? 'banner' : 'custom_group'}_message`}
					onCancel={() => setCustomGroupToRemove(null)}
					onConfirm={() => {
						deleteCustomGroup(customGroupToRemove);
						setCustomGroupToRemove(null);
					}}
				/>
			</ProductHighlightContainer>
	);
};

export default ProductHighlight