import React, { useState, useContext, useEffect } from 'react';
import { EventRow } from './EventRow';
import { TicketRow } from './TicketRow';
import { EventsTableContainer } from './EventsContainers';
import { useTranslation, Trans } from 'react-i18next';
import Spinner from 'common/Spinner';
import EmptyState from 'common/EmptyState';
import { Button, BUTTON_VARIANTS, BUTTON_SIZES } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Context as AuthContext } from '../../data/authContext';
import { saveEventOrTicket } from './EventApi';
import { cloneDeep } from "lodash";
import { SquareIcon } from 'common/Icon';

export const EventsTable = ({ eventsList, selectedEventId, isLoading, setSelectedEventId, setEventToEdit, setTicketToEdit, setEventDraft, mutate, setPopup, eventTickets, setTicketDraft, setTab, ticketsStock }) => {
  const { t } = useTranslation();
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const restaurant = selectedRestaurant[0].value

  const [tickets, setTickets] = useState([])

  const toggleEventOpen = (eventId) => {
    setSelectedEventId((prevId) => (prevId === eventId ? null : eventId));
  };

  useEffect(() => {
    const ticketsForEvent = eventTickets
    .filter(ticket => ticket.event === selectedEventId)
    .sort((a,b) => a.order - b.order)
    .map(ticket => {
      const matchingStocks = ticketsStock.filter(stock => stock.event_ticket === ticket.id);

      return {
        ...ticket, 
        stocks: matchingStocks 
      };
    });
    setTickets(ticketsForEvent)
  }, [eventTickets, selectedEventId, ticketsStock])

  const alterOrderTicket = async(ticket) => {
    try {
      await saveEventOrTicket({
        restaurant: restaurant,
        eventOrTicket: 'TICKET',
        eventToEdit: null,
        ticketToEdit: ticket,
        object: ticket, 
        t: t
      })

      mutate();
      setTimeout(() => {
      }, 3000);

    } catch (error) {
      setTickets(eventTickets)
      mutate();
      console.log("🚀 saveEvent error:", error);
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newTickets = cloneDeep(tickets)

    if (source.index === destination.index && source.droppableId === destination.droppableId) return;

		const [myTicket] = newTickets.splice(source.index, 1);
    myTicket.order = destination.index
		newTickets.splice(destination.index, 0, myTicket);
		let newtickets = newTickets.map((ticket, index) => ({ ...ticket, order: index }))

    setTickets(newtickets)

    alterOrderTicket(myTicket)
  };

  return (
    <EventsTableContainer>
      <table className='table-events'>
  
        <thead>
          <tr>
            <th>
              {t('name')}
            </th>
            <th>
              <div>
              </div>
            </th>
            <th>
              <div>
              </div>
            </th>
            <th>
              <div>
              </div>
            </th>
            <th>
              <div>
              </div>
            </th>
            <th>
              <div>
              </div>
            </th>
            <th>
              <div>
                {t('Status')}
              </div>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {!isLoading && (!eventsList || eventsList?.length === 0) &&
              <td style={{border: 'none'}} colSpan='9'>
                  <EmptyState 
                    text={<Trans i18nKey='create_your_first_event'>
                      <b>Crea nuevos eventos</b>y vende tickets a través de Yumminn
                    </Trans>} 
                    icon={ <SquareIcon>🎭</SquareIcon>}
                  />
                </td>
            }
          </tr>
          {!isLoading && eventsList?.map(ev => (
            <React.Fragment key={ev.id}>   
                <EventRow
                  event={ev}
                  editEvent={() => setEventToEdit(ev)}
                  open={ev.id === selectedEventId}
                  onToggleOpen={() => toggleEventOpen(ev.id)}
                  setEventDraft={setEventDraft}
                  mutate={mutate}
                  restaurant={restaurant}
                  setTab={setTab}
                />
              {ev.id === selectedEventId && (
              <>
              {tickets.some(ticket => ticket.event === selectedEventId) ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId={`droppable-${ev.id}`} type="TICKET">
                    {(provided) => (
                        <tr ref={provided.innerRef} {...provided.droppableProps}>
                          <td style={{padding: "0"}} colSpan='9'>
                            <table className={`${ev.id === selectedEventId ? 'selected' : ''} ${!ev?.active ? 'disabled' : ''}`}>
                              {tickets
                                .map((ticket, index) => (
                                  <Draggable key={ticket.id} draggableId={ticket.id.toString()} index={index}>
                                    {(provided) => (
                                      <TicketRow
                                        key={ticket.id}
                                        ticket={ticket}
                                        editTicket={() => setTicketToEdit(ticket)}
                                        setTicketDraft={setTicketDraft}
                                        mutate={mutate}
                                        provided={provided}
                                        restaurant={restaurant}
                                        innerRef={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      />
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                <tr>
                                  <td colSpan="9" style={{borderTop: "1px dashed #DDD"}}>
                                    <span  />
                                  </td>
                                </tr>
                                <tr className="custom-button-cell">
                                  <td colSpan="7"></td> {/* Empty cells to span columns 1 to 7 */}
                                  <td className="custom-button-wrapper ">
                                    <Button 
                                      size={BUTTON_SIZES.SM}
                                      variant={BUTTON_VARIANTS.PRIMARY}
                                      label='add_ticket'
                                      leftIcon={<Icon type={IconType.ADD} />}
                                      onClick={() => {setPopup(2); setTicketDraft({ event: ev.id })}}
                                    />
                                  </td>
                                </tr>
                            </table>
                          </td>
                        </tr>
                    )}
                  </Droppable>
                
              </DragDropContext>
              ) : (
                <tr className={`${ev.id === selectedEventId ? 'ticket selected-no-tickets selected' : ''}`}>
                  <td style={{background: "#FFF", borderWidth: '1px', borderRadius: 'none', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopRightRadius: 0, borderTop: 'none' }} colSpan="9" className='!text-center'>
                    <br></br>
                    <span>{t('create_your_tickets')}</span> 🎟️
                    <Button
                      onClick={() => {setPopup(2); setTicketDraft({ event: ev.id })}}
                      style={{ margin: "10px -110px 25px -110px", position: "relative", top: "50%", left: "50%" }}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      label='add_ticket'
                      leftIcon={<Icon type={IconType.ADD} />}
                    />
                  </td>
                </tr>
              )}
              </>
            )}
            </React.Fragment>
          ))}

          {isLoading &&
            <tr>
              <td style={{border: 'none'}} colSpan='9'>
                <Spinner className='spinner'/>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </EventsTableContainer>
  );
};