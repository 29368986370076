const light = {
  text: '#404040',
  background_sunken: '#E1E1E1',
  background: '#FCFCFC',
  background_rised: '#FFFFFF',
  background_contrast: '#E1E1E1',
  header_bg: '#404040',
  card_border_color: '#EEEEEE',
  border: '#EEEEEE',
  backdrop_color: '#00000099',
};

const red = {
  text: '#B62F36',
  background_sunken: '#F3F0E1',
  background: '#FFFDF0',
  background_rised: '#FFFEF8',
  background_contrast: '#F3F0E1',
  header_bg: '#C8102E',
  card_border_color: '#EFEFED',
  border: '#EFEFED',
  backdrop_color: '#00000099',
};

const burgundy = {
  text: '#FFFFFF',
  background_sunken: '#6C1123',
  background: '#791C2F',
  background_rised: '#891C2F',
  background_contrast: '#6C1123',
  header_bg: '#6C1123',
  card_border_color: '#F0CA89',
  border: '#F0CA89',
  backdrop_color: '#7E7E7E80',
};

const dark = {
  text: '#FFFFFF',
  background_sunken: '#161A1D',
  background: '#212529',
  background_rised: '#3F4449',
  background_contrast: '#161A1D',
  header_bg: '#404040',
  card_border_color: 'none',
  border: '#212529',
  backdrop_color: '#7E7E7E80',
}

const blue = {
  text: '#FFFFFF',
  background_sunken: '#002E3D',
  background: '#00485E',
  background_rised: '#03607C',
  background_contrast: '#002E3D',
  header_bg: '#002E3D',
  card_border_color: 'none',
  border: '#00485E',
  backdrop_color: '#00000099',
};

const blue_linear = {
  text: '#F1FFF9',
  background_sunken: '#02313C',
  background: '#094553',
  background_rised: '#1C6070',
  background_contrast: '#02313C',
  header_bg: '#033642',
  card_border_color: '#DFFFF1',
  border: '#094553',
  backdrop_color: '#00000099',
};

const superdark = {
  text: '#FFFFFF',
  background_sunken: '#000',
  background: '#000',
  background_rised: '#212529',
  background_contrast: '#DDEEFF22',
  header_bg: '#212529',
  card_border_color: 'none',
  border: '#000',
  backdrop_color: '#7E7E7E80',
};

const superdark_linear = {
  text: '#FFFFFF',
  background_sunken: '#000000',
  background: '#000000',
  background_rised: '#171717',
  background_contrast: '#DDEEFF22',
  header_bg: '#171717',
  card_border_color: '#FFFFFF',
  border: '#000000',
  backdrop_color: '#7E7E7E80',
};

export const mockThemes = {
  light,
  red,
  burgundy,
  dark,
  blue,
  blue_linear,
  superdark,
  superdark_linear,
};