import { orderAndPay } from 'logic/api';
import useSWR from 'swr';
import { addSchedules, compareFn } from './OrderAndPay/Logic/functions';
import { getTranslationLanguage } from './utils';

const EMPTY_DATA = {
  catalog: null,
  lastSynchro: '',
  tpv: '',
  isTspoonlab: false,
  childrenOptions: {
    SUPER_GROUP: [],
    CATEGORY_GROUP: [],
    CATEGORY: [],
  },
};

const parseProducts = prodCategory => prodCategory.forEach(c => {
  try {
    c.parent_id = c.category_group;
    c.products = [...c.products].sort(compareFn);
    c.products.forEach(p => p.parent_id = c.id);
  } catch {
    c.products = [];
  }
});

const formatCatalog = data => {
  if (!data || !data.products_and_categories) return EMPTY_DATA;
  
  const { products_and_categories } = data;

  const getOptions = list => list?.map(item => ({value: item.id, label: item.name || item.tpv_name})) || [];
  const childrenOptions = {
    SUPER_GROUP: getOptions(products_and_categories?.category_groups),
    CATEGORY_GROUP: getOptions(products_and_categories?.product_categories),
    CATEGORY: getOptions(products_and_categories?.product_categories?.map(cat => cat.products).flat().filter(prod => !!prod)),
  };

  if (!products_and_categories?.supergroups?.length) {
    const product_categories = products_and_categories.product_categories.sort(compareFn);

    parseProducts(product_categories);
    return {
      catalog: { product_categories },
      childrenOptions,
      lastSynchro: data?.lastSynchro || '',
      tpv: data?.tpv || '',
      isTspoonlab: data?.tspoonlab_connection || false,
    };
  };

  //ADD VISIBILITY SCHEDULES
  if (products_and_categories?.visibility_schedules['supergroup_visibility_schedules']) {
    const superGroupSchedules = products_and_categories.visibility_schedules['supergroup_visibility_schedules'];
    products_and_categories.supergroups = addSchedules(products_and_categories.supergroups, superGroupSchedules)
  }
  if (products_and_categories?.visibility_schedules['categorygroup_visibility_schedules']) {
    const categoryGroupSchedules = products_and_categories.visibility_schedules['categorygroup_visibility_schedules'];
    products_and_categories.category_groups = addSchedules(products_and_categories.category_groups, categoryGroupSchedules)
  }
  if (products_and_categories?.visibility_schedules['productcategory_visibility_schedules']) {
    const productCategorySchedules = products_and_categories.visibility_schedules['productcategory_visibility_schedules'];
    products_and_categories.product_categories = addSchedules(products_and_categories.product_categories, productCategorySchedules)
  }
  const supergroups = products_and_categories.supergroups.map(s => ({...s})).sort(compareFn);
  
  products_and_categories.category_groups.map(c => ({...c})).sort(compareFn).forEach(l2 => {
    const l1Index = supergroups.findIndex(l1 => l2.category_supergroup ? l2.category_supergroup === l1.id : l2.tpv_category_supergroup === l2.tpv_category_supergroup);
    
    if (l1Index === -1) {
      console.error(new Error(`Supergroup ${l2.category_supergroup} not found. Category ${l2.id}.`));
      return;
    }

    l2.parent_id = l2.category_supergroup;
    
    const product_categories = products_and_categories.product_categories.filter(l3 => l3.category_group ? l3.category_group === l2.id : l3.tpv_category_group === l2.id).sort(compareFn);
    if (products_and_categories?.visibility_schedules['product_visibility_schedules']) {
      product_categories.forEach(l3 => {     
        const productSchedules = products_and_categories.visibility_schedules['product_visibility_schedules'];
        l3.products = addSchedules(l3.products, productSchedules)
      })
    }
    parseProducts(product_categories)
    
    if (!supergroups[l1Index].category_groups) {
      supergroups[l1Index].category_groups = [];
    };

    supergroups[l1Index].category_groups.push({...l2, product_categories});
  });

  return {
    catalog: {
      supergroups,
      numberOfLevels: products_and_categories?.number_of_levels || 0,
    },
    childrenOptions,
    lastSynchro: data?.last_synchronization_datetime || '',
    tpv: data?.tpv || '',
    isTspoonlab: data?.tspoonlab_connection || false,
  };
};

const fetcher = async endpoint => {
  try {
    const res = await orderAndPay.get(endpoint);
    return formatCatalog(res.data);
  } catch (error) {
    console.error(error);
    return EMPTY_DATA;
  }
};

export const useOrderAndPay = ({selectedRestaurantId, sectorId}) => {
  const lang = getTranslationLanguage();
  let url = selectedRestaurantId ? `/catalog?restaurant=${selectedRestaurantId}&language=${lang}` : null

  if(sectorId){
    url += `&sectorId=${sectorId}`
  }

  const { data, isLoading, isValidating, mutate } = useSWR(
    url,
    fetcher,
    {revalidateOnFocus: false},
  );
  
  return {
    ...(data || EMPTY_DATA),
    isLoading,
    isValidating,
    mutate,
  };
};