import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '../../../../styles/customFilepond.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { registerPlugin } from 'react-filepond';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../../data/authContext';
import { apiAuth, baseAuth } from 'logic/api';
import { sayError } from 'logic/defaults';
import { CoverPreview, HomePreview, MobileMockup } from 'components/previews';
import Spinner from 'common/Spinner';
import { ChevronDownIcon, ChevronUpIcon, PencilIcon } from '@heroicons/react/outline';
import { LogoCustomization } from './components/LogoCustomization';
import { ColorSettings } from './components/ColorSettings';
import { SplashSettings } from './components/SplashSettings';
import { RestaurantImageSettings } from './components/RestaurantImageSettings';
import { MenuSettings } from './components/MenuSettings';
import { RestaurantGroupSettings } from './components/RestaurantGroupSettings';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { RestaurantGroupPreview } from 'components/previews/screens/RestaurantGroupPreview';
import { TitleSettings } from './components/TitleSettings';
import { DeliveryTakeawayHomePreview } from 'components/previews/screens/DeliveryTakeawayHomePreview';
import { DeliveryTakeawayColorsSettings } from './components/DeliveryTakeawayColorsSettings';

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

export const Customization = () => {
  const { t } = useTranslation();
  const {state: { selectedRestaurantId }} = useContext(AuthContext);
  

  const [customizationType, setCustomizationType] = useState("restaurant");
  const [selectedOption, setSelectedOption] = useState(null);
  const [preview, setPreview] = useState("home");
  const [data, setData] = useState(null);
  const [rgData, setRgData] = useState(null);
  const [dtData, setDtData] = useState(null);


  const [logo, setLogo] = useState(null);
  const [cornerLogo, setCornerLogo] = useState(null);
  const [homeImage, setHomeImage] = useState(null);
  const [theme, setTheme] = useState('light');
  const [colors, setColors] = useState({primary: '#5FB894', secondary: '#FFFFFF', tertiary: null});
  const [photoOverlay, setPhotoOverlay] = useState(true);
  const [logoWidth, setLogoWidth] = useState(50);
  const [cornerLogoWidth, setCornerLogoWidth] = useState(20);
  const [splashLogoWidth, setSplashLogoWidth] = useState(null);
  const [expandedProductCards, setExpandedProductCards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //RESTAURANT GROUP DATA
  const [restaurantGroupUuid, setRestaurantGroupUuid] = useState(null);
  const [restaurantGroupRestaurants, setRestaurantGroupRestaurants] = useState(null);
  const [rgTheme, setRgTheme] = useState('light');
  const [rgLogo, setRgLogo] = useState(null);
  const [rgTitle, setRgTitle] = useState(null);
  const [rgSubtitle, setRgSubtitle] = useState(null);
  const [rgBackgroundImage, setRgBackgroundImage] = useState(null);

  //DELIVERY-TAKEAWAY DATA
  const [dtUuid, setDtUuid] = useState(null);
  const [dtTheme, setDtTheme] = useState('light');
  const [dtLogo, setDtLogo] = useState(null);
  const [dtTitle, setDtTitle] = useState(null);
  const [dtBackgroundImage, setDtBackgroundImage] = useState(null);
  const [dtColors, setDtColors] = useState({delivery: '#5FB894', takeaway: '#5FB894'});

  const RESTAURANT_CUSTOMIZATION_OPTIONS = [
    {"label": "logo", "left_emoji": "🧩", "component": <LogoCustomization logo={logo} logoWidth={logoWidth} setLogo={setLogo} setLogoWidth={setLogoWidth}/>},
    {"label": "colors", "left_emoji": "🎨", "component": <ColorSettings colors={colors} theme={theme} setColors={setColors} setTheme={setTheme}/>},
    {"label": "splash_design", "left_emoji": "✨", "component": <SplashSettings colors={colors} splashLogoWidth={splashLogoWidth} setColors={setColors}/>, "preview": "cover"},
    {"label": "restaurant_image", "left_emoji": "📷", "component": <RestaurantImageSettings homeImage={homeImage} photoOverlay={photoOverlay} setHomeImage={setHomeImage} setPhotoOverlay={setPhotoOverlay}/>},
    {"label": "secondary_logo", "left_emoji": "🤝", "component": <LogoCustomization logo={cornerLogo} logoWidth={cornerLogoWidth} setLogo={setCornerLogo} setLogoWidth={setCornerLogoWidth} fieldName="corner_logo"/>},
    {"label": "menu_design", "left_emoji": "🍴", "component": <MenuSettings expandedProductCards={expandedProductCards} setExpandedProductCards={setExpandedProductCards}/>, "preview": "home"},
  ]

  const RESTAURANT_GROUP_CUSTOMIZATION_OPTIONS = [
    {"label": "title_subtitle", "left_emoji": "✏️", "component": <TitleSettings title={rgTitle} setTitle={setRgTitle} subtitle={rgSubtitle} setSubtitle={setRgSubtitle}/>},
    {"label": "logo", "left_emoji": "🧩", "component": <LogoCustomization logo={rgLogo} setLogo={setRgLogo} fieldName="header_image"/>},
    {"label": "design", "left_emoji": "🎨", "component": <ColorSettings theme={rgTheme} setTheme={setRgTheme} backgroundImage={rgBackgroundImage} setBackgroundImage={setRgBackgroundImage}/>},
    {"label": "restaurants", "left_emoji": "⚙️", "component": <RestaurantGroupSettings restaurantGroupRestaurants={restaurantGroupRestaurants} setRestaurantGroupRestaurants={setRestaurantGroupRestaurants} theme={rgTheme}/>},
  ]

  const RESTAURANT_DELIVERY_TAKEAWAY = [
    {"label": "title", "left_emoji": "✏️", "component": <TitleSettings title={dtTitle} setTitle={setDtTitle} />},
    {"label": "logo", "left_emoji": "🧩", "component": <LogoCustomization logo={dtLogo} setLogo={setDtLogo} fieldName='image'/>},
    {"label": "design", "left_emoji": "🎨", "component": <DeliveryTakeawayColorsSettings colors={dtColors} theme={dtTheme} setColors={setDtColors} setTheme={setDtTheme} backgroundImage={dtBackgroundImage} setBackgroundImage={setDtBackgroundImage}/>},
  ]

  const CUSTOMIZATION_OPTIONS = {
    "restaurant": RESTAURANT_CUSTOMIZATION_OPTIONS, 
    "restaurant_group": RESTAURANT_GROUP_CUSTOMIZATION_OPTIONS,
    "delivery_takeaway": RESTAURANT_DELIVERY_TAKEAWAY,
  }

  const changeSelectedCustomization = (customization_type, preview_type) => {
    setCustomizationType(customization_type); 
    setPreview(preview_type); 
    setSelectedOption(null)
  }

  const uploadImage = async (fd, auth, endpoint) => {
    try {
      await auth(endpoint, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: fd,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const save = async () => {    
    const newData = {};
    const endpoint = `/restaurants/${selectedRestaurantId}/`
    logoWidth !== data.logo_width && (newData.logo_width = logoWidth);    
    cornerLogoWidth !== data.corner_logo_width  && (newData.corner_logo_width = cornerLogoWidth);
    splashLogoWidth !== data.splash_logo_width  && (newData.splash_logo_width = splashLogoWidth);    
    theme !== data.theme && (newData.theme = theme);
    expandedProductCards !== data.expanded_product_cards && (newData.expanded_product_cards = expandedProductCards);
    photoOverlay !== data.photo_overlay && (newData.photo_overlay = photoOverlay);
    logo?.fd && uploadImage(logo.fd, apiAuth, endpoint);
    homeImage?.fd && Object.fromEntries(homeImage.fd)?.home_image?.name !== "blob" && uploadImage(homeImage.fd, apiAuth, endpoint);
    cornerLogo?.fd && uploadImage(cornerLogo.fd, apiAuth, endpoint);
    Object.entries(colors).forEach(([color, value]) => {
      if (data[`${color}_color`] === value) return;
      newData[`${color}_color`] = value;
    });
    if (!Object.keys(newData).length) {
      return
    };
    try {
      await apiAuth(endpoint, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: newData,
      });
      toast.success(t('changes_applied'));
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const saveRestaurantGroup = async () => {    
    const newData = {};
    const endpoint = `/restaurant/group/${restaurantGroupUuid}/customization`
    rgTitle !== rgData.title && (newData.title = rgTitle);
    rgSubtitle !== rgData.subtitle && (newData.subtitle = rgSubtitle);
    rgTheme !== rgData.theme && (newData.theme = rgTheme);
    rgLogo?.fd && uploadImage(rgLogo.fd, baseAuth, endpoint);
    rgBackgroundImage?.fd && uploadImage(rgBackgroundImage.fd, baseAuth, endpoint);
    restaurantGroupRestaurants !== rgData.restaurants && (newData.restaurants = restaurantGroupRestaurants);
    newData.restaurants.forEach((restaurant) => {
      if (restaurant?.image?.fd) {
        uploadImage(restaurant?.image?.fd, baseAuth, `/restaurant/group-restaurant/${restaurant.id}/customization`)
      }
      delete restaurant["image"]
      delete restaurant["restaurant"]
    });
    if (!Object.keys(newData).length) {
      return
    };
    try {
      await baseAuth(endpoint, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: newData,
      });
      toast.success(t('changes_applied'));
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const saveDeliveryTakeaway = async () => {    
    const newData = {};
    const endpoint = `/restaurant/delivery-takeaway-landing/${dtUuid}/customization`
    dtTitle !== dtData.title && (newData.title = dtTitle);
    dtTheme !== dtData.theme && (newData.theme = dtTheme);
    dtLogo?.fd && uploadImage(dtLogo.fd, baseAuth, endpoint);
    dtBackgroundImage?.fd && uploadImage(dtBackgroundImage.fd, baseAuth, endpoint);
    Object.entries(dtColors).forEach(([color, value]) => {
      if (dtData[`color_${color}`] === value) return;
      newData[`color_${color}`] = value;
    });
    if (!Object.keys(newData).length) {
      return
    };
    try {
      await baseAuth(endpoint, {
        method: 'PATCH',
        'Content-Type': 'multipart/form-data',
        data: newData,
      });
      toast.success(t('changes_applied'));
    } catch (error) {
      console.error(error);
      toast.error(sayError(error.response.data));
    }
  };

  const handleClickOption = (option) => {
    selectedOption == option.label ? setSelectedOption(null) : setSelectedOption(option.label)
    selectedOption == option.label ? setPreview(customizationType == "restaurant_group" ? "restaurant_group" : customizationType == "delivery_takeaway" ? "delivery_takeaway" : "home") : setPreview(option.preview ? option.preview : customizationType == "restaurant_group" ? "restaurant_group" : customizationType == "delivery_takeaway" ? "delivery_takeaway" : "home"); 
  }


  const getData = async (id) => {
    setIsLoading(true);
    try {
      const { data: newData } = await apiAuth(`/restaurants/${id}/`, {method: 'GET'});
      setData(newData)
      setLogo(newData?.logo ? {url: newData?.logo, file: newData?.logo} : null);
      setCornerLogo(newData?.corner_logo ? {url: newData?.corner_logo, file: newData?.corner_logo} : null);
      setHomeImage(newData?.home_image ? {url: newData?.home_image, file: newData?.home_image} : null);
      setPhotoOverlay(newData?.photo_overlay);
      setLogoWidth(newData?.logo_width || 50);
      setCornerLogoWidth(newData?.corner_logo_width || 20);
      setSplashLogoWidth(newData?.splash_logo_width || 75);
      setExpandedProductCards(newData?.expanded_product_cards || false);
      setTheme(newData?.theme || 'light');
      
      setColors({
        primary: newData?.primary_color,
        secondary: newData?.secondary_color,
        tertiary: newData?.tertiary_color || null,
      });

      //Restaurant group data
      const restaurant_group = newData?.restaurant_group && newData?.restaurant_group.length > 0 ? newData?.restaurant_group[0] : null
      setRgData(restaurant_group);
      setRestaurantGroupUuid(restaurant_group ? restaurant_group.uuid : null || null);
      if (restaurant_group && restaurant_group.restaurants) {
        restaurant_group.restaurants.forEach((restaurant) => {
          if (restaurant.image) {
            restaurant.image = {url: restaurant?.image, file: restaurant?.image}
          }
        })
      }
      setRestaurantGroupRestaurants(restaurant_group ? restaurant_group.restaurants : null || null);
      setRgTheme(restaurant_group ? restaurant_group.theme : 'light')
      const rg_logo = restaurant_group ? restaurant_group.header_image : null
      setRgLogo(rg_logo ? {url: rg_logo, file: rg_logo} : null)
      setRgTitle(restaurant_group ? restaurant_group.title : null)
      setRgSubtitle(restaurant_group ? restaurant_group.subtitle : null)
      const rg_bg_image = restaurant_group ? restaurant_group.background_image : null
      setRgBackgroundImage(rg_bg_image ? {url: rg_bg_image, file: rg_bg_image} : null)

      //Delivery-Takeaway data
      const delivery_takeaway = newData?.delivery_takeaway && newData?.delivery_takeaway.length > 0 ? newData?.delivery_takeaway[0] : null
      setDtData(delivery_takeaway)
      setDtUuid(delivery_takeaway?.uuid || null)
      setDtTitle(delivery_takeaway?.title || null)
      setDtTheme(delivery_takeaway?.theme || 'light')
      const dt_logo = delivery_takeaway?.image || null
      setDtLogo(dt_logo ? {url: dt_logo, file: dt_logo} : null)
      const dt_background = delivery_takeaway?.background || null
      setDtBackgroundImage(dt_background ? {url: dt_background, file: dt_background} : null)
      setDtColors({
        delivery: delivery_takeaway?.color_delivery || "#5FB894",
        takeaway: delivery_takeaway?.color_takeaway  || "#5FB894",
      });

    } catch (error) {
      console.error(error);
      error?.response?.data && toast.error(sayError(error.response.data));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    selectedRestaurantId && getData(selectedRestaurantId);
  }, [selectedRestaurantId]);

  return (
    <div className=''>
      {isLoading &&
        <>
          <div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center bg-gray-100 bg-opacity-50">
            <Spinner>{t("Loading")}</Spinner>
          </div>
        </>
      }
      {(restaurantGroupUuid || dtUuid) &&
      <div className='flex gap-5 mb-10'>
        <Button
          label={t('Restaurant')}
          onClick={() => {changeSelectedCustomization("restaurant", "home")}}
          variant={customizationType == "restaurant" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
        />
        {restaurantGroupUuid &&
        <Button
          label={t('home_restaurant_group')}
          onClick={() => {changeSelectedCustomization("restaurant_group", "restaurant_group")}}
          variant={customizationType == "restaurant_group" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
        />}
        {dtUuid &&
        <Button
          label={t('home_delivery_takeaway')}
          onClick={() => {changeSelectedCustomization("delivery_takeaway", "delivery_takeaway")}}
          variant={customizationType == "delivery_takeaway" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
        />}
      </div>}
      <div className='flex gap-[150px] relative'>
      <div className='flex flex-col relative w-[35%] gap-6'>
        <div className='bg-white rounded-2xl relative py-4 px-6 pb-3 max-h-[100%]' style={{overflowY: 'auto'}}>
          <div className='flex gap-2 items-center'>
            <PencilIcon height={24}/>
            <h2 className='font-bold text-lg'>
              {customizationType == "restaurant" ? t('customize_webapp') : t('customize_landing')}
            </h2>
          </div>
          <hr className='my-3'/>
          {(CUSTOMIZATION_OPTIONS[customizationType]).map((option, index) => (
            <>
            <div className='flex gap-2 items-center justify-between pr-5 cursor-pointer' onClick={() => handleClickOption(option)}>
              <h3 className='text-[15px] flex gap-3'>
                <span>{option.left_emoji}</span>
                {t(option.label)}
              </h3>
              {selectedOption === option.label ? 
                  <ChevronUpIcon
                    style={{height: "35px"}}
                    className='rounded-full cursor-pointer p-[3px]'
                  /> : 
                  <ChevronDownIcon
                    style={{height: "35px"}}
                    className='rounded-full cursor-pointer p-[3px]'
              />}
              </div>
              {selectedOption === option.label && 
                option.component
              }    
            <hr className='my-3'/>
            </>
          ))}     
          <div style={{position: 'sticky', bottom: 0, display: 'flex', justifyContent: 'center'}}>
            <Button
              label={t('update')}
              style={{padding: "0 65px"}}
              onClick={() => customizationType == "restaurant" ? save() : customizationType == "restaurant_group" ? saveRestaurantGroup() : saveDeliveryTakeaway()}
              variant={BUTTON_VARIANTS.PRIMARY}/>  
          </div>         
        </div>
      </div>
      <div className='flex flex-col gap-6 items-start'>
        <div className='flex gap-6'>
          {preview &&
          <div>
            <MobileMockup>
            {preview == "cover" &&
              <CoverPreview color={colors.secondary} logo={logo?.url} logoWidth={splashLogoWidth}/>}
            {preview == "home" &&
              <HomePreview themeName={theme} colors={colors} logo={logo?.url} homeImage={homeImage?.url} logoWidth={logoWidth} photoOverlay={photoOverlay} cornerLogo={cornerLogo?.url} cornerLogoWidth={cornerLogoWidth}/>}
            {preview == "delivery_takeaway" &&
              <DeliveryTakeawayHomePreview themeName={dtTheme} colors={dtColors} logo={dtLogo?.url} homeImage={dtBackgroundImage?.url} title={dtTitle}/>}
            {preview == "restaurant_group" &&
              <RestaurantGroupPreview themeName={rgTheme} logo={rgLogo?.url} backgroundImage={rgBackgroundImage?.url} title={rgTitle} subtitle={rgSubtitle} restaurants={restaurantGroupRestaurants}/>}
            </MobileMockup>
          </div>}
        </div>
      </div>
    </div>
  </div>
  );
};
