import React, { useEffect, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { ClockIcon, XIcon } from "@heroicons/react/outline";
import './customDatePickerStyle.css';
import { EventImage } from "./EventImage";
import { getImageUrl } from 'logic/functions';
import { EventsDatePicker } from "./EventsDatePicker";
import moment from 'moment';
import { Icon, IconType } from "common/Icon";
import { SelectInput } from "common/Input";
import { components } from 'react-select';
import { RecurrencyEndDate, ExcludedDates, ExcludedDatesDropdown } from "./EventsContainers";
import { Tooltip } from 'common/Tooltip';
import { cloneDeep } from "lodash";
import { generateRandomId, generateRecurrenceDates } from "./EventsLogic";

export const EventSpecs = ({
  eventDraft,
  editEvent, 
  tab,
  eventToEdit
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(eventDraft.name);
  const [description, setDescription] = useState(eventDraft.description);
  const [charCount, setCharCount] = useState(description ? description?.length : 0);
  const [location, setLocation] = useState(eventDraft.location);
  const [selectedDates, setSelectedDates] = useState(eventDraft.event_dates)
  const [selectedTimes, setSelectedTimes] = useState([])
  const [dates, setDates] = useState([...eventDraft.event_dates, ...eventDraft.recurring_event_dates])
  const [selectFinalDateOpen, setSelectFinalDateOpen] = useState({});
  const [endRecurrencyDates, setEndRecurrencyDates] = useState({});
  const [excludedDatesOpen, setExcludedDatesOpen] = useState({});
  const maxCharCount = 2000;
  const containerRef = useRef(null);


  useEffect(() => {
    document.getElementById("popupDiv").classList.add("events")

    if (containerRef.current) {
      // Desplaza el scroll hacia abajo al último elemento
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [selectedDates]);


  // Toggle calendar open state for a specific event
  const toggleCalendar = (eventDateId) => {
    //const eventKey = getEventKey(date, recurrenceType);
    setSelectFinalDateOpen(prev => {
      // Create an object with all keys set to false
      const allClosed = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      // Set only the selected eventKey to true, toggle if it was already open
      if(!prev[eventDateId]){
        scrollSelectionIntoView();
      }
      return {
        ...allClosed,
        [eventDateId]: !prev[eventDateId],
      };
    });  
  };

  // Handler to update end date per event key
  const handleSelectedDates = (date, eventKey) => {
    setEndRecurrencyDates(prev => ({
      ...prev,
      [eventKey]: date
    }));
  };

  const handleExcludedDates = (open, eventDateId) => {
    setExcludedDatesOpen(prev => ({
      ...prev,
      [eventDateId]: !open
    }));
  };


  const handleDescriptionChange = (description) => {
    if (description.length <= maxCharCount) {
      setDescription(description);
      setCharCount(description.length);
      editEvent({ description });
    }
  };

  const handleLocationChange = (location) => {
    setLocation(location);
    editEvent({ location });
  };

  const handleNameChange = (name) => {
    setName(name);
    editEvent({ name });
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  const handleFileSelect = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      editEvent({ image: imageUrl, imageFile: selectedFile });
    } 
  };

  const saveImage = () => {
    const imageUrl = getImageUrl(eventDraft.image);
    window.open(imageUrl, '_blank');
  };

  const deleteImage = () => {
    editEvent({ image: "", imageFile: null });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]; // Get the first file
    if (file && file.type.startsWith("image/")) {
      handleFileSelect({ target: { files: [file] } }); // Trigger file select with the dropped image
    }
  };
  
  const handleDatePick = (dates) => {
    if (eventToEdit) {
        let selDates =  [];
        
        if (dates.length > 0) {
            dates.forEach((date) => {
              const formattedDate = moment(date.date).format('YYYY-MM-DD');
              
              // Check if the date is already in the array
              if (!selDates.some((entry) => entry.date === formattedDate)) {
                const entry = { date: formattedDate };
      
                // Add `id` if it exists
                if (date.id) {
                  entry.id = date.id;
                }
      
                // Add `eventDate` if it exists
                if (date.event_date) {
                  entry.event_date = date.event_date;
                }

                selDates.push(entry);
      
              };
            });
        } else {
            selDates = [];
        }

        setSelectedDates(selDates);
    }
  };

  useEffect(() => {
    const today = new Date();
    const sameDateNextYear = moment(new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())).format('YYYY-MM-DD');
    if(selectedTimes.length === 0 && eventToEdit){
      // Merge recurring and non-recurring dates

      const uniqueEntries = new Set();

      const datesToUse = [
        ...(eventDraft.event_dates || []).map(entry => ({
            id: entry.id,
            date: entry.date,
            time: entry.time.slice(0, 5),
            recurrence_type: entry.recurrence_type === 'daily' ? "each_day" :
                            entry.recurrence_type === 'weekly' ? "every_month_the_weekday" :
                            entry.recurrence_type === 'monthly' ? "every_month_the_date" :
                            "not_repeated",
            recurrence_end_date: entry.recurrence_end_date,
            recurrence_excluded_dates: entry.recurrence_excluded_dates || []  
        })),
        ...(eventDraft.recurring_event_dates || []).map(entry => ({
            event_date: entry.event_date || entry.id,
            date: entry.date,
            time: entry.time.slice(0, 5),
            recurrence_type: entry.recurrence_type === 'daily' ? "each_day" :
                            entry.recurrence_type === 'weekly' ? "every_month_the_weekday" :
                            entry.recurrence_type === 'monthly' ? "every_month_the_date" :
                            "not_repeated",
            recurrence_end_date: entry.recurrence_end_date || sameDateNextYear,
            recurrence_excluded_dates: entry.recurrence_excluded_dates || []
        }))
      ].filter(entry => {
        // Generate a unique key for date and time
        const uniqueKey = `${entry.date}-${entry.time}`;
        
        // Check if the unique key already exists in the set
        if (uniqueEntries.has(uniqueKey)) {
            return false; // Duplicate found, filter it out
        }
        
        uniqueEntries.add(uniqueKey);
        return true; // Unique entry, keep it
    });

    setSelectedTimes(datesToUse);

    }
  }, [eventDraft, eventToEdit]);

  const options = [];
  const interval = 15; // 15 minutes

  for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
          const formattedHour = String(hour).padStart(2, '0'); 
          const formattedMinutes = String(minutes).padStart(2, '0'); 
          const time = `${formattedHour}:${formattedMinutes}`;

          options.push({ value: time, label: time });
      }
  }

  const deleteTime = (recurrenceType, date, eventDateId) => {

    const datesToDelete = eventDraft?.event_dates_to_delete || [] 
    const newDatesToDelete = [...datesToDelete, eventDateId]; 

    console.log({eventDateId})

    const newFilteredTimes = [...selectedTimes].filter(entry => entry.date === date && entry.recurrence_type === recurrenceType)
    const eventEntriesIds = []
    newFilteredTimes.map(entry => eventEntriesIds.push(entry.id))
    const isRecurring = selectedTimes.some(entry => entry.recurrence_type === recurrenceType && !(['none', 'not_repeated'].includes(recurrenceType)))
    //filter dates that do not belong to the pertaining event_date
    let filteredTimes = [...selectedTimes].filter(entry => !eventEntriesIds.includes(entry.id));
    let updatedDates = [...selectedDates].filter(entry => !eventEntriesIds.includes(entry.id));
    if (isRecurring) {
      filteredTimes = [...selectedTimes].filter(entry => (!eventEntriesIds.includes(entry.id) && !eventEntriesIds.includes(entry.event_date)))
      updatedDates = [...selectedDates].filter(entry => (!eventEntriesIds.includes(entry.id) && !eventEntriesIds.includes(entry.event_date)))
      //editEvent({ recurring_event_dates: filteredTimes })
    }

    const correspondingNewTimes = filteredTimes.filter((newTime) => newTime.hasOwnProperty('id'))
    editEvent({ event_dates: correspondingNewTimes, event_dates_to_delete: newDatesToDelete })

    // Update states
    setSelectedDates(updatedDates);
    setSelectedTimes(filteredTimes);

  }

  const deleteExcludedDate = (initialRecurringDate, excluded) => {
  
    const eventEntry = selectedTimes.find(entry => entry.date === initialRecurringDate); //find event_date

    if (eventEntry) {
      const updatedExcludedDates = eventEntry.recurrence_excluded_dates.filter(excluded_date => excluded_date !== excluded); //remove new excluded date from recurrence_excluded_dates list

      // update times with recurrence_excluded_dates list 
      const updatedTimes = selectedTimes.map(entry => 
        entry.date === initialRecurringDate 
            ? { ...entry, recurrence_excluded_dates: updatedExcludedDates } 
            : entry
      );

      const excludedDate = moment(excluded)
      const initialRecurringDateMoment = moment(initialRecurringDate)

      if(excludedDate.isBefore(initialRecurringDateMoment)){
        // Replace the `id` property with `event_date` in the matching entry
        const matchingEntryIndex = updatedTimes.findIndex(entry => entry.id === eventEntry.id);

        if (matchingEntryIndex !== -1) {
          updatedTimes[matchingEntryIndex].event_date = updatedTimes[matchingEntryIndex].id // add the event_date property to previous event_date
          delete updatedTimes[matchingEntryIndex].id; // Remove the `id` property
        }
      }

      //find out if new excluded date was already present in selectedTimes
      const existingRecurringEntryIndex = updatedTimes.findIndex(entry => entry.date === excluded);

      //if new excluded date was not present, create new entry
      if (existingRecurringEntryIndex === -1) {
        const newEntry = { 
          ...(excludedDate.isBefore(initialRecurringDateMoment) 
          ? { id: eventEntry.id } 
          : { event_date: eventEntry.id }), // Conditionally add either `id` or `event_date`
          date: excluded, 
          time: eventEntry.time, 
          recurrence_type: eventEntry.recurrence_type,
          recurrence_end_date: eventEntry.recurrence_end_date,
          recurrence_excluded_dates: updatedExcludedDates 
        };

        const insertIndex = updatedTimes.findIndex(entry => entry.date > excluded);
          if (insertIndex === -1) {
              // If no larger date is found, push to the end
              updatedTimes.push(newEntry);
          } else {
              // Otherwise, insert at the found index
              updatedTimes.splice(insertIndex, 0, newEntry);
          }
      }

      const recurringEventDates = updatedTimes.length > 0 ? updatedTimes : [];
      const correspondingNewTimes = recurringEventDates.filter((newTime) => newTime.hasOwnProperty('id'))
      editEvent({
        event_dates: [...new Set(correspondingNewTimes)]
      })
      editEvent({ 
          recurring_event_dates: [...new Set(recurringEventDates)], // Ensure unique dates
      });
      setSelectedTimes([...updatedTimes]);

      const updatedDates = [...selectedDates]

      if(excludedDate.isBefore(initialRecurringDateMoment)){
        // Replace the `id` property with `event_date` in the matching entry
        const matchingEntryIndex = updatedDates.findIndex(entry => entry.id === eventEntry.id);

        if (matchingEntryIndex !== -1) {
          updatedDates[matchingEntryIndex].event_date = updatedDates[matchingEntryIndex].id // add the event_date property to previous event_date
          delete updatedDates[matchingEntryIndex].id; // Remove the `id` property
        }
      }

      if (!updatedDates.some((entry) => entry.date === excluded)) {
        // Create a new entry object for the excluded date
        const newEntry = { 
          date: excluded, 
          ...(excludedDate.isBefore(initialRecurringDateMoment) 
          ? { id: eventEntry.id } 
          : { event_date: eventEntry.id })
        };
      
        // Find the correct position to maintain order based on the `date` property
        let index = updatedDates.findIndex((entry) => entry.date > excluded);
        if (index === -1) {
          // If no larger date is found, push to the end
          updatedDates.push(newEntry);
        } else {
          // Otherwise, insert at the found index
          updatedDates.splice(index, 0, newEntry);
        }
      }
      setSelectedDates(updatedDates);
    }
  }

  const addExcludedDate = (initialRecurringDate, excluded) => {

    const eventEntries = selectedTimes.filter(entry => entry.date === initialRecurringDate);

    if (!eventEntries || eventEntries.length === 0) return;

    // Create updatedTimes where excluded date is removed from selectedTimes
    let updatedTimes = selectedTimes
        .filter(entry => entry.date !== excluded) // Remove the excluded date from selectedTimes
        .map(entry => {
            // Check if any eventEntries match recurrence_type and event_date
            const matchingEvent = eventEntries.find(eventEntry =>
                entry.recurrence_type === eventEntry.recurrence_type && (entry.id === eventEntry.event_date || entry.id === eventEntry.id || entry.event_date === eventEntry.id || entry.eventDate === entry.id)
            );

            return matchingEvent
                ? { ...entry, recurrence_excluded_dates: [...entry.recurrence_excluded_dates, excluded] }
                : entry;
        });

    // Remove the excluded date from selectedDates
    let updatedDates = selectedDates.filter(selectedDate => selectedDate.date !== excluded);
    
    // Iterate through each eventEntry to handle properties like `id` and `event_date`
    eventEntries.forEach(eventEntry => {
        const index = selectedTimes.findIndex(entry => entry.date === initialRecurringDate);
        if (index !== -1) {
            if (eventEntry.hasOwnProperty('id')) {
                updatedTimes[index].id = eventEntry.id;
                delete updatedTimes[index].event_date;
                const dateIndex = selectedDates.findIndex(date => date.date === initialRecurringDate);
                if (dateIndex !== -1) {
                    updatedDates[dateIndex].id = eventEntry.id;
                    delete updatedDates[dateIndex].event_date;
                }
            }
        } 
    });

    // Finally, update selectedTimes and selectedDates
    setSelectedTimes(updatedTimes);
    setSelectedDates(updatedDates);

    // Optionally, update your event with the modified recurring event dates
    const recurringEventDates = updatedTimes.length > 0 ? updatedTimes : [];
    const correspondingNewTimes = recurringEventDates.filter(newTime => newTime.hasOwnProperty('id'));
    editEvent({
        event_dates: [...new Set(correspondingNewTimes)]
    });
  };


  const scrollSelectionIntoView = () => {
    setTimeout(() => {
      if (containerRef.current && (containerRef.current.scrollTop < containerRef.current.scrollHeight)) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 0)
  }

  const handleTime = (eventDateId, times, date, recurrenceType, recurrenceExcludedDates, recurrenceEndDate) => {
    const newTimes = [...selectedTimes];
    console.log({selectedTimes})

    // Get times for the selected date from current state
    const previousTimesForDate = selectedTimes
    .filter(entry => entry.date === date && entry.recurrence_type === recurrenceType)
    .map(entry => entry.time);

    console.log({previousTimesForDate})

    // Get times that have been newly added or removed by comparing with previous state
    const newTimesForDate = times.map(t => t.value); // Array of selected times for this date
    const addedTimes = newTimesForDate.filter(t => !previousTimesForDate.includes(t));
    const removedTimes = previousTimesForDate.filter(t => !newTimesForDate.includes(t));

    console.log({removedTimes})

    if (previousTimesForDate.length === 1 && removedTimes.length > 0) {
      console.log("Cannot remove the last remaining time for this date.");
      return; // Stop here to prevent removing the last item
    }
    
    // Handle added times, creating new event_dates
    addedTimes.forEach(addedTime => {
      newTimes.push({
          id: previousTimesForDate.length === 0 ? eventDateId : generateRandomId(),
          date,
          time: addedTime,
          recurrence_type: recurrenceType ? recurrenceType : 'none',
          recurrence_excluded_dates: recurrenceExcludedDates,
          recurrence_end_date: recurrenceEndDate
      });
    });

    let idsToRemove = []

    // Handle removed times (only if it won't result in zero times for this date)
    removedTimes.forEach(removedTime => {
        const indexToRemove = newTimes.findIndex(
            entry => entry.date === date && entry.time === removedTime && entry.recurrence_type === recurrenceType
        );
        const idToRemove = newTimes.find(
          entry => entry.date === date && entry.time === removedTime && entry.recurrence_type === recurrenceType
        ).id

        idsToRemove = [...idsToRemove, idToRemove]
        if (indexToRemove !== -1) {
            newTimes.splice(indexToRemove, 1);
        }
    });

    // Update state
    setSelectedTimes(newTimes);

    const correspondingNewTimes = newTimes.filter((newTime) => newTime.hasOwnProperty('id'))

    const payload = {
      event_dates: correspondingNewTimes,
      ...(idsToRemove.length > 0 && { event_dates_to_delete: idsToRemove }), // Conditionally add event_dates_to_delete
    };

    editEvent(payload)

  };

  useEffect(() => {
    handleDatePick(dates)
  }, [dates])


  const Option = (props) => {
    return(
      <components.Option {...props} >
      <div style={{display: 'flex', justifyContent: 'flex-start', gap: "10px", alignItems: 'center'}}>
        <input
          id={`checkbox-${props.value}`}
          className="timeSelector"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label htmlFor={`checkbox-${props.value}`}>{props.label}</label>
        <span/>
      </div>
    </components.Option>
    )
  }

  
  if(tab === 'Details'){
    return (
      <div className="flex gap-8 w-full my-2">
      
        <div className="cursor-pointer flex flex-col items-center justify-center mt-6 gap-6 pl-14">
  
          <div 
            colSpan={1} 
            className={`bg-[#EDEDED] rounded-3xl relative min-w-[250px] min-h-[250px] items-center flex flex-col shadow mb-4 ${eventDraft.image ? '' : 'border-2 border-dashed border-gray-400'}`} 
            onClick={handleButtonClick}
            onDragOver={(e) => e.preventDefault()} 
            onDrop={handleDrop} 
          >
       
            <EventImage object={eventDraft} type='event'/>
  
            <div className="flex flex-row gap-4 items-center mt-2 absolute right-4">
              
              {eventDraft.image && 
                <div
                  id="download button"
                  className="cursor-pointer bg-[#FFF] rounded-full p-3 hover:text-principal"
                  onClick={saveImage}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </div>
              }
              {eventDraft.image && 
                <button
                  type='button'
                  onClick={deleteImage}
                  className='bg-[#FFF] p-2 rounded-full'
                >
                  <XIcon className='h-8 w-8 text-[#212121] hover:text-principal' />
                </button>
              }
  
            </div>
  
            {/* HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
            <input
              type="file"
              accept="image/*" // Limit to image files
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileSelect}
            />
  
          </div>
          {/*HIDDEN INPUT NEEDED FOR IMAGE SELECT DIALOGUE */}
          <div style={{textAlign: 'center', padding: "0 20px"}}>
            <Trans className='text-center' i18nkey='photo_suggestions'>
              <b>Sugerencias:</b> png/jpg o gif de 2MB máx de peso.
            </Trans> 
          </div>
  
        </div>
  
        <div className="flex flex-col gap-4 w-full py-4 px-14">
  
          <div className="flex flex-col justify-between gap-2 items-start w-full text-base">
            <label className="whitespace-nowrap" htmlFor="name">
              {t("name of the event")}
            </label>
            <input
              id="name"
              className="input font-medium rounded-[10px] input-bordered h-14 w-full border-2 border-[#D4D4D8] placeholder-gray-400 pl-3"
              value={name}
              placeholder={eventDraft.name || t('write here')}
              onChange={(e) => handleNameChange(e.target.value)}
            />
          </div>
  
          <div className="flex flex-col justify-between gap-2 items-start text-base">
            <label className="whitespace-nowrap" htmlFor="address">
              {t("Address")}
            </label>
            <input
              id="address"
              className="input font-medium rounded-[10px] input-bordered h-14 w-full border-2 border-[#D4D4D8] placeholder-gray-400 pl-3"
              value={location}
              placeholder={eventDraft.location || t('write here')}
              onChange={(e) => handleLocationChange(e.target.value)}
            />
          </div>
  
          <div className="flex flex-col justify-between gap-2 items-start w-full relative">
            <label
              className="whitespace-nowrap self-start mt-2 text-base"
              htmlFor="description"
            >
              {t("Description")} ({t('optional')})
            </label>
            <textarea
              id="description"
              className="input input-bordered rounded-[10px] border-2 border-[#D4D4D8] font-medium w-full h-[120px]"
              value={description}
              placeholder={eventDraft.description || t('write here')}
              onChange={(e) => handleDescriptionChange(e.target.value)}
            ></textarea>
            <div style={{position: 'absolute', bottom: "12px", right: "10px"}} className="self-end text-sm text-gray-500">
              {charCount}/{maxCharCount}
            </div>
          </div>
  
        </div>
      </div>
    );
  }

  if(tab === 'Date and hour'){

    const handleRecurrencyOptions = (e, date, endDate) => {

      const recurrenceType = e.value;
      const eventEntry = selectedTimes.find(entry => entry.date === date);
      const eventDateId = eventEntry?.id
  
      // If no entry is found, return early
      if (!eventEntry || !eventEntry.time || eventEntry.recurrence_type === recurrenceType) {
          return;
      }
  
      const today = new Date();
      const sameDateNextYear = moment(new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())).format('YYYY-MM-DD');
  
      // Get the recurrence end date if it's set for the event
      const recurrenceEndDate = endDate || eventEntry.recurrence_end_date || sameDateNextYear;
  
      // Now, compare and remove any dates that are no longer valid
      const updatedTimes = [...selectedTimes].map(entry => {
          if (entry.date === date) {
              return { 
                  ...entry,
                  recurrence_type: recurrenceType,
                  recurrence_end_date: recurrenceEndDate,
                  recurrence_excluded_dates: entry.recurrence_excluded_dates || []
              };
          }
          return entry;
      });
  
      // Now, we want to add the newly generated recurrence dates to selectedTimes
      const newSelectedTimes = cloneDeep(updatedTimes);
  
      // Sort the times so that they remain in order
      newSelectedTimes.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? -1 : 1);
  
      // Update selectedTimes with the newly calculated set
      setSelectedTimes(newSelectedTimes);
      //editEvent({ recurring_event_dates: newSelectedTimes });

      let correspondingNewTimes = newSelectedTimes.filter((newTime) => newTime.hasOwnProperty('id'))
      // editEvent({ event_dates: correspondingNewTimes });

      // Generate the new recurrence dates based on the selected type
      const newRecurrenceDates = generateRecurrenceDates(
          eventEntry.date,
          recurrenceType,
          recurrenceEndDate
      );
      // Update selectedDates
      let updatedSelectedDates = [...selectedDates].filter((selDate) => selDate.event_date !== eventEntry.id || selDate.hasOwnProperty('id'));
      const test = [...updatedTimes].filter((updatedTime) => updatedTime.event_date !== eventEntry.event_date || updatedTime.hasOwnProperty('id'))

      test.forEach((time) => {
        if(time.id === eventEntry.id){
          newRecurrenceDates.forEach(newDate => {
            if (!updatedSelectedDates.some((entry) => entry.date === newDate)) {
              const entry = { date: newDate };

              // Add `id` if it exists
              if (time.id) {
                entry.event_date = time.id;
              }

              updatedSelectedDates.push(entry)
              updatedTimes.push({
                date: newDate, 
                event_date: time.id, 
                recurrence_type: recurrenceType,
                recurrence_end_date: recurrenceEndDate,
                recurrence_excluded_dates: entry.recurrence_excluded_dates || []
              })
            }     
          })
        } 
      })
  
      // Sort the selected dates in chronological order
      updatedSelectedDates.sort((a, b) => moment(a.date).diff(moment(b.date)));
  
      // Finally, update selectedDates
      setSelectedDates(updatedSelectedDates);

      // Update excluded dates if needed
      const updatedExcludedDates = updatedTimes.map(entry => {
        if (entry.recurrence_type === recurrenceType && (entry.id === eventDateId || entry.event_date === eventDateId)) {
            // Filter out excluded dates that are beyond the recurrence end date
            const validExcludedDates = entry.recurrence_excluded_dates.filter(excludedDate => {
                const excludedMoment = moment(excludedDate);
                return excludedMoment.isBefore(endDate) || excludedMoment.isSameOrBefore(endDate);
            });
            return {
                ...entry,
                recurrence_excluded_dates: validExcludedDates
            };
        }
        return entry;
      });

      setSelectedTimes(updatedExcludedDates);
      //editEvent({ recurring_event_dates: updatedExcludedDates });
      correspondingNewTimes = updatedExcludedDates.filter((newTime) => newTime.hasOwnProperty('id'))
      editEvent({ event_dates: correspondingNewTimes });
      
    };

    const handleRecurrenceEndDate = (e, date, endDate) => {

      const recurrenceType = e.value;
      const eventEntry = selectedTimes.find(entry => entry.date === date);
  
      // If no entry is found, return early
      if (!eventEntry || !eventEntry.time || eventEntry.recurrence_type !== recurrenceType) {
        return;
      }
  
      const today = new Date();
      const sameDateNextYear = moment(new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())).format('YYYY-MM-DD');
  
      // Get the recurrence end date if it's set for the event
      const recurrenceEndDate = endDate || eventEntry.recurrence_end_date || sameDateNextYear;

      const newSelectedTimes = cloneDeep(selectedTimes);
      const newEventEntry = newSelectedTimes.find(entry => entry.date === date)
      newEventEntry.recurrence_end_date = recurrenceEndDate

      let correspondingNewTimes = newSelectedTimes.filter((newTime) => newTime.hasOwnProperty('id'))

      const updatedTimes = selectedTimes.map(entry => {
        if (entry.date === date) {
            return { 
                ...entry,
                recurrence_type: recurrenceType,
                recurrence_end_date: recurrenceEndDate,
                recurrence_excluded_dates: entry.recurrence_excluded_dates || []
            };
        }
        return entry;
      });

      const test = [...updatedTimes].filter((updatedTime) => updatedTime.event_date !== eventEntry.event_date || updatedTime.hasOwnProperty('id'))

      const newRecurrenceDates = generateRecurrenceDates(
        eventEntry.date,
        recurrenceType,
        recurrenceEndDate
      );
      // Update selectedDates
      let updatedSelectedDates = [...selectedDates].filter((selDate) => selDate.event_date !== eventEntry.id || selDate.hasOwnProperty('id'));

      test.forEach((time) => {
        if(time.id === eventEntry.id){
          newRecurrenceDates.forEach(newDate => {
              if (!updatedSelectedDates.some((entry) => entry.date === newDate)) {
                const entry = { date: newDate };

                // Add `id` if it exists
                if (time.id) {
                  entry.event_date = time.id;
                }

                updatedSelectedDates.push(entry)
                updatedTimes.push({
                  date: newDate, 
                  event_date: time.id, 
                  recurrence_type: recurrenceType,
                  recurrence_end_date: recurrenceEndDate,
                  recurrence_excluded_dates: entry.recurrence_excluded_dates || []
                })
              }    
          })
        }
      })


      // Update excluded dates if needed
      const updatedExcludedDates = updatedTimes.map(entry => {
        if (entry.recurrence_type === recurrenceType) {
            // Filter out excluded dates that are beyond the recurrence end date
            const validExcludedDates = entry.recurrence_excluded_dates.filter(excludedDate => {
                const excludedMoment = moment(excludedDate);
                return excludedMoment.isBefore(endDate) || excludedMoment.isSameOrBefore(endDate);
            });
            return {
                ...entry,
                recurrence_excluded_dates: validExcludedDates
            };
        }
        return entry;
      });

      setSelectedDates(updatedSelectedDates)
      setSelectedTimes(updatedExcludedDates);
      //editEvent({ recurring_event_dates: updatedExcludedDates });
      correspondingNewTimes = updatedExcludedDates.filter((newTime) => newTime.hasOwnProperty('id'))
      editEvent({ event_dates: correspondingNewTimes });
      
    };  
    
    return ( 
      <div className="flex mt-4">
        <div className="flex flex-col items-center">
          <h2 className="text-[16px] font-medium">{t('choose one or several dates')}</h2>
          <EventsDatePicker 
            selectedDates={selectedDates} 
            setSelectedDates={setSelectedDates} 
            selectedTimes={selectedTimes} 
            addExcludedDate={addExcludedDate}
          />

        </div>

        <div className={`flex flex-col items-center pl-14 w-full ${selectedDates && selectedDates.length === 0 ? 'justify-center' : 'justify-start'}`}>

        {selectedDates && selectedDates.length === 0 ? 
          <div className='flex flex-col gap-4 items-center text-[16px] font-medium'>
            <p>🍂</p>
            <div>
              {t('you_have_not_selected_any_dates_yet')}
            </div>
          </div>
          :
          <div 
            ref={containerRef}
            style={{minWidth: "30vw", height: "55vh", overflowX: "hidden", overflowY: "scroll"}} 
            className='flex flex-col gap-4 text-[16px] font-medium'
          >
            <h2>
              {t('selection')}:
            </h2>
            {selectedDates.filter((selectedDate) => selectedDate.hasOwnProperty('id')).map((selDate, idx) => {

              const date = selDate.date
              const formattedDate = moment(date).format("D");
              const weekdayUppercase = t(moment(date).format("dddd"));
              const weekday = weekdayUppercase.toLocaleLowerCase();

              const recurrencyOptions = [
                {label: t("not_repeated"), value: "not_repeated"}, 
                {label: t("each_day"), value:"each_day"},
                {label: t("every_month_the_date", { date: formattedDate }), value:"every_month_the_date"},
                {label: t("every_month_the_weekday", { weekday }), value:"every_month_the_weekday"}
              ]

              const eventDateId = selDate?.id;
              const recurrenceType = selectedTimes.find(selectedTime => selectedTime?.id === eventDateId)?.recurrence_type;
              const recurrenceExcludedDates = selectedTimes.find(selectedTime => selectedTime?.id === eventDateId)?.recurrence_excluded_dates || [];
              const recurrenceEndDate = selectedTimes.find(selectedTime => selectedTime?.id === eventDateId)?.recurrence_end_date || null;
              
              return(
                
                <div key={idx} className='flex flex-col gap-4'> 
                  <div className='flex gap-3 items-center'>
                    <span className='mr-2 min-w-[100px]'>{moment(date).format('DD/MM/YYYY')}</span>
                    <SelectInput
                      placeholder={
                        selectedTimes.filter(entry => entry.date === date).length === 0 ?
                          <div className='flex gap-2'>
                            <ClockIcon style={{color: '#094553'}} className={`w-[25px] text-[#094553]`}/>
                            <p>{t('timetable')}</p>
                          </div>
                        : selectedTimes.filter(entry => entry.date === date).length === 1 ? 
                        <div className='flex gap-2'>
                          <ClockIcon style={{color: '#094553'}} className={`w-[25px] text-[#094553]`}/>
                          <p className='text-[#094553] font-semibold'>{selectedTimes.filter(entry => entry.date === date)[0].time}</p>
                        </div>
                        :
                        <div className='flex gap-2'>
                          <ClockIcon style={{color: '#094553'}} className={`w-[25px] text-[#094553]`}/>
                          <p className='text-[#094553] font-semibold'>{selectedTimes.filter(entry => entry.date === date).length + ' ' +  t('hours')}</p>
                        </div>
                      }
                      id={'time'}
                      name={'time'}
                      isClearable={false}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      value={selectedTimes.filter(entry => entry.date === date && entry.recurrence_type === recurrenceType).map(entry => ({ value: entry.time, label: entry.time }))}
                      options={options}
                      className={'w-48 mr-2 min-w-14'}
                      onChange={(time) => handleTime(selDate?.id, time, date, recurrenceType, recurrenceExcludedDates, recurrenceEndDate)}
                      components={{Option}}
                      isMulti
                      controlShouldRenderValue={false}
                      menuShouldScrollIntoView
                      //onFocus={scrollSelectionIntoView}
                      onMenuOpen={scrollSelectionIntoView}
                    />
                    <SelectInput
                      placeholder={t('not_repeated')}
                      id={'recurrency'}
                      name={'recurrency'}
                      isClearable={false}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={true}
                      value={recurrencyOptions.find(({ value }) =>
                        value === selectedTimes.find(entry => entry.date === date && entry.recurrence_type === recurrenceType)?.recurrence_type
                      )}
                      options={recurrencyOptions}
                      className={'w-48 mr-2 min-w-14'}
                      onChange={(e) => handleRecurrencyOptions(e, date)}
                      components={{Option}}
                      disabled={!selectedTimes.find(entry => entry?.date === date && entry?.time)}
                      onMenuOpen={scrollSelectionIntoView}
                    />
                    <Icon type={IconType.TRASH} onClick={() => deleteTime(recurrenceType, date, eventDateId)}/>
                  </div>
                  <div style={{display: 'flex', gap: "15px"}}>
                    {selectedTimes.find(entry => entry.date === date && entry.recurrence_type === recurrenceType)?.recurrence_type &&
                    ['each_day', 'every_month_the_weekday', 'every_month_the_date'].includes(selectedTimes.find(entry => entry.date === date && entry.id === eventDateId)?.recurrence_type) &&
                      <RecurrencyEndDate 
                        onClick={() => toggleCalendar(eventDateId)}
                      >
                        <span>{t("recurrence until") + " "}</span><span style={{fontWeight: 600, marginRight: "10px"}}>{selectedTimes.find(entry => entry.date === date)?.recurrence_end_date}</span>
                        <Icon type={IconType.CHEVRON_DOWN}/>
                      </RecurrencyEndDate>
                    } 
                      {selectedTimes.find(entry => entry.id === eventDateId && entry.date === date && entry.recurrence_type === recurrenceType)?.recurrence_type &&
                      ['each_day', 'every_month_the_weekday', 'every_month_the_date'].includes(selectedTimes.find(entry => entry.date === date && entry.id === eventDateId)?.recurrence_type) && 
                      <ExcludedDates onClick={() => recurrenceExcludedDates.length > 0 && handleExcludedDates(excludedDatesOpen[eventDateId], eventDateId)}>
                        <span style={{fontWeight: 600, marginLeft: "10px"}}>{recurrenceExcludedDates.length}</span><span>{recurrenceExcludedDates.length === 1 ? t('excluded_date') : t('excluded_dates')}</span>
                        {recurrenceExcludedDates.length !== 0 && <p>{t('see')}</p>}
                        {recurrenceExcludedDates.length !== 0 && <Icon type={IconType.CHEVRON_DOWN}/>}    
                        {excludedDatesOpen[eventDateId] && 
                          <ExcludedDatesDropdown>
                            {excludedDatesOpen[eventDateId] && recurrenceExcludedDates.length > 0 && recurrenceExcludedDates.map((excluded, index) => {
                              return(
                                <span key={index}>
                                  {excluded}
                                  <Icon type={IconType.TRASH} onClick={() => deleteExcludedDate(date, excluded)}/>
                                </span>
                              )
                            })}
                          </ExcludedDatesDropdown>
                        }               
                        {recurrenceExcludedDates.length === 0 &&
                          <Tooltip position='bottom'>
                            <div style={{color: "#FFFFFF"}}>{t('excluded_dates_explanation')}</div>
                          </Tooltip>
                        } 
                      </ExcludedDates> 
                    }
                   
                  </div>
                  {selectFinalDateOpen[eventDateId] && 
                    <EventsDatePicker 
                      selectedDates={endRecurrencyDates[eventDateId] || selectedTimes.find(entry => entry.date === date)?.recurrence_end_date}
                      setSelectedDates={(date) => handleSelectedDates(date, eventDateId)}
                      singleToggleDateSelection={true} 
                      handleRecurrenceEndDate={handleRecurrenceEndDate} 
                      recurringInitialDate={date} 
                      recurrenceType={recurrencyOptions.find(({ value }) => value === selectedTimes.find(entry => entry.date === date)?.recurrence_type)} 
                      newStyles={true}
                      onClose={() => toggleCalendar(date, recurrenceType)} 
                    />
                  }
                  <hr></hr>
                </div>
              )}
            )}
          </div>
        }
        </div>
      </div>   
    )
  }
  
};
