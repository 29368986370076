import styled from "styled-components";
import { getTextColor } from "styles/colors";

export const Tooltip = ({ children, position = 'right', bgColor = '#094553', left, size, interactive = false  }) => {
  return (
    <TooltipContainer
      className={`yumminn_tooltip ${position} ${interactive ? 'interactive' : ''}`}
      bgColor={bgColor}
      left={left}
      size={size}
    >
      {children}
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 1.3;
  text-align: left;
  color: ${({bgColor}) => getTextColor(bgColor)};
  background-color: ${({bgColor}) => bgColor};
  border-radius: 8px;
  padding: 0.5rem;
  z-index: 99;
  isolation: isolate;
  pointer-events: none;
  opacity: 0;
  scale: 0;
  transition: 0.2s ease-in-out;
  filter: drop-shadow(4px 4px 0 #0001) drop-shadow(4px 4px 6px #0001);

  &.interactive {
    pointer-events: all;
  }

  & > p {
    width: max-content;
    max-width: ${({size}) => size || '16rem'};
    text-wrap: balance;
  }

  b {
    font-weight: 600;
  }
  
  &::after {
    content: '';
    position: absolute;
    rotate: 45deg;
    background-color: inherit;
    height: 12px;
    width: 12px;
    z-index: 0;
  }

  &.right {
    left: calc(100% + 8px);
    top: 50%;
    translate: 0 -50%;
    transform-origin: left;
    margin-left: 4px;
    border: 1px solid #0001;

    &::after {
      top: 50%;
      left: -6px;
      translate: 0 -50%;
      border-radius: 0 2rem 0 0.5rem;
      border-bottom: 1px solid #0001;
      border-left: 1px solid #0001;
    }
  }

  &.left {
    right: calc(100% + 8px);
    top: 50%;
    translate: 0 -50%;
    transform-origin: right;
    margin-right: 4px;
    border: 1px solid #0001;

    &::after {
      top: 50%;
      right: -6px;
      translate: 0 -50%;
      border-radius: 0 0.5rem 0 2rem;
      border-top: 1px solid #0001;
      border-right: 1px solid #0001;
    }
  }

  &.top {
    bottom: calc(100% + 8px);
    left: 50%;
    translate: -50% 0;
    transform-origin: bottom;
    margin-bottom: 4px;
    border: 1px solid #0001;

    &::after {
      left: 50%;
      bottom: -6px;
      translate: -50% 0%;
      border-radius: 2rem 0 0.5rem 0;
      border-bottom: 1px solid #0001;
      border-right: 1px solid #0001;
    }
  }

  &.bottom {
    top: calc(100% + 8px);
    left: ${({left}) => left ? left : "50%"};
    translate: -50% 0;
    transform-origin: top;
    margin-top: 4px;
    border: 1px solid #0001;

    &::after {
      left: 50%;
      top: -6px;
      translate: -50% 0%;
      border-radius: 0.5rem 0 2rem 0;
      border-top: 1px solid #0001;
      border-left: 1px solid #0001;
    }
  }
`;