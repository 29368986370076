import React from 'react';
import XTextButton from 'common/XTextButton';
import { Context as AuthContext } from 'data/authContext';
import { api, apiAuth, translation } from 'logic/api';
import { bottomRoutes, routes } from "../../components/AdminRoutes"
import { admin_routes } from "../../components/SuperAdminRoutes"
import { waiter_routes } from "../../components/WaiterRoutes"
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Logo } from "common/Logo"
import { TextInput } from 'common/Input';
import { Icon, IconType } from 'common/Icon';
import { useViewport } from 'data/ViewportContext';

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    state: { error },
    loadUser,
    loadSuperUser,
    setIsLoggedIn,
    setAllowedRoutes
  } = React.useContext(AuthContext);
  const {isMobile, isTablet} = useViewport();

  const [submitting, setSubmitting] = React.useState(false);
  const [state, setState] = React.useState({
    email: '',
    password: '',
    origin: 'backoffice'
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const tabsAndLanding = async(restaurants, boSections, tuser, res, landingRoute) => {
    if (restaurants.filter((item) => item.show_order_and_pay_tab_on_backoffice).length > 0) {
      const tabRes = await translation.get(`/tabs`);
      localStorage.setItem('tabs', JSON.stringify(tabRes.data.tabs));
    }
    if(boSections.length > 0){
      const buttons = tuser.data.is_superuser ? admin_routes(t, res.data.tabs, boSections) : tuser.data.rol !== "waiter" ? routes(t, res.data.tabs, boSections) : waiter_routes(t, res.data.tabs, boSections)
      const lastButtons = bottomRoutes({handleLogout: null, t: t, allowedRoutes: boSections})
      const allButtons = [...buttons, ...lastButtons];
      landingRoute = findLandingRoute(allButtons) 
      return landingRoute;
    }
    return landingRoute;
  }

  const findLandingRoute = (buttons) => {
    const button = buttons.find((button) => button.path || button.children.some(element => element.path));
    
    return button ? (button.path || (button.children.find(element => element.path) || {}).path) : null;
  };

  const handleLogin = async () => {
    setSubmitting(true);
    try {
      const res = await api.post(`/users/login`, state);
      const boSections = res.data.bo_sections
      setAllowedRoutes(boSections);
      localStorage.setItem('token', res.data.token);

      const tuser = await apiAuth.get(`/users/retrieve?w=1`);
      let landingRoute = tuser.data.rol === "waiter" ? '/payments' : '/admin/home'
      setIsLoggedIn(true);

      if (tuser.data.is_admin) {
        await loadUser(res.data.token);
        let restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
        landingRoute = await tabsAndLanding(restaurants, boSections, tuser, res, landingRoute) 

        if (tuser.data.rol === "waiter" && restaurants.length > 0) {
          localStorage.setItem('yumminn-path', 'payments');
          history.push(landingRoute);
        } else if(restaurants.length > 0){
          localStorage.setItem('yumminn-path', 'home');
          history.push(landingRoute);
        } else {
          toast.error("Error")
        }
      } else if (tuser.data.is_superuser || tuser.data.is_investor) {
          await loadSuperUser(res.data.token);
          let restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'))
          landingRoute = await tabsAndLanding(restaurants, boSections, tuser, res, landingRoute);

          localStorage.setItem('yumminn-path', 'dashboard');
          if(restaurants.length > 0){
            history.push(landingRoute);
          }
          else{
            toast.error("Error")
          }
      }
    } catch (error) {
        if(error && error.response && error.response.data === "Invalid credentials"){
          toast.error(t('Invalid sent data'))
        } else{
          toast.error(error)
        }
      } finally {
        setSubmitting(false);
      }
    };

  return (
    <div className='flex h-screen'>

      {(!isMobile && !isTablet) &&
        <div style={{textAlign: 'center'}} className='flex-1 bg-[#094553] relative justify-center items-center content-center justify-items-center text-center'>

          <Logo size={50} className="flex flex-col px-6"/>

          <div style={{ width: "100%", height: "auto" }} className='absolute top-0 right-[-50%]'>
            <Icon type={IconType.SEMI_CIRCLE_BOTTOM_PART} />
          </div>

          <div style={{ width: "100%", height: "auto" }} className='absolute bottom-0'>
            <Icon type={IconType.SEMI_CIRCLE_TOP_PART_WITH_FILLING} />
          </div>
          

        </div>
      }

      <div className='flex-1 bg-white justify-center items-center content-center justify-items-center z-10 relative overflow-hidden'>
      <form 
        onSubmit={() => console.log('first')} 
        className={`relative z-[10] flex flex-col md:w-3/4 sm:w-11/12 lg:max-w-md h-50 p-3 flex-column justify-around items-center ${(isMobile || isTablet) ? 'space-y-4' : 'space-y-8'}`}
      > 

          <div style={{fontSize: 'clamp(1.75rem,2vw,2.35rem)'}} className='font-semibold text-[#094553] capitalize'>
            {t('welcome')}
          </div>
          <div style={{marginTop: "1rem", textAlign: 'center', fontSize: 'clamp(1rem,2vw,1.35rem)'}} className='font-normal text-[#094553] text-center'>
            {t('login to your yumminn account')}
          </div>

        
          <div className={'px-2 w-full'}>
         
            <TextInput
              name='email'
              label={<div style={{fontSize: 'clamp(1rem,2vw,1.15rem)'}} className={'font-medium text-sm capitalize py-2'}>{t('email')}<span style={{color: 'red'}}>*</span></div>}
              placeholder={t('email')}
              value={state.email || ''}
              onChange={handleChange}
            />
           
          </div>
          
          <div className={'px-2 w-full relative'}>
         
            <TextInput
              password
              name='password'
              label={<div style={{fontSize: 'clamp(1rem,2vw,1.15rem)'}} className={'font-medium text-sm capitalize py-2 text-[16px]'}>{t('password')}<span style={{color: 'red'}}>*</span></div>}
              placeholder={t('password')}
              value={state.password || ''}
              onChange={handleChange}
            />

            <Link to='/forgot'>
              <div className='text-[#094553] underline mt-4' style={{ textAlign: 'left', fontSize: 'clamp(0.875rem,2vw,1rem)'}}>{t('i_forgot_my_password')}</div>
            </Link>

          </div>

          <XTextButton
            type='submit'
            disabled={submitting}
            submitting={submitting}
            doClick={handleLogin}
            isMobileOrTablet={(isMobile || isTablet)}
          >
            {t('login')}
          </XTextButton>

         
        </form>

        <div style={{width: "25%", height: "20%"}} className="absolute bottom-0 right-0 bg-white border-[8px] overflow-visible border-none rounded-br-2xl">
          <div style={{borderRadius: "0px 0px 18rem", width: (isMobile || isTablet) ? "195%" : "160%", height: (isMobile || isTablet) ? "15%" : "27%", rotate: (isMobile || isTablet) ? "-238deg" : "-230deg"}} className="bg-[#9DEECB] absolute"></div>
          <div style={{clipPath: "polygon(100% 0, 100% 100%, 0% 100%)", width: (isMobile || isTablet) ? "4rem" : "9rem", height: (isMobile || isTablet) ? "7rem": "11rem"}} className="absolute bottom-0 right-0 bg-[#094553]"></div>
        </div>

      </div>
    </div>
  );
};

export default Login;
