export default function XTextButton({ children, submitting, doClick, isMobileOrTablet = false, type = 'button' }) {
  return (
    <div className={'px-2 w-full'}>
      <button
        type={type}
        disabled={submitting}
        className={
          `rounded-full w-full cursor-pointer ${isMobileOrTablet ? "py-2 px-3 text-[16px]" : "py-4 px-5 text-[18px]"} m-1 font-semibold text-white bg-[#094553] hover:bg-[#D4F9EE] hover:text-[#094553]`
        }
        onClick={doClick}
      >
        <div className={'flex space-sm justify-center'}>
          {submitting && (
            <div>
              <svg
                className='animate-spin -ml-1 mr-3 h-5 w-5 text-black-50'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                />
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                />
              </svg>
            </div>
          )}
          <div>{children}</div>
        </div>
      </button>
    </div>
  );
}
