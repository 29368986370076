import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import { translation } from "logic/api";
import Spinner from "common/Spinner";
import SpinnerButton from "common/SpinnerButton";
import { CheckCircleIcon, SearchIcon} from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../data/authContext';
import { useHistory } from 'react-router-dom';
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { logToServer } from "logic/functions";
import { Icon, IconType } from 'common/Icon';
import { PaginationButton } from 'common/PaginationButton';
import "./Translations.css"
import { InfoTooltip } from 'common/InfoTooltip';
import { CustomToast } from 'common/CustomToast';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import TargetTraductionModelSelector from './TargetTraductionModelSelector';
import { Pagination } from 'common/Pagination';
import { LanguageSelector } from './LanguageSelector';
import { TableRevisionLegend } from './TableRevisionLegend';
import { AiTranslationProgressPopup } from './AiTranslationProgressPopup';
import { translateAiCatalogService } from './translation.service';
import { AiTranslationsDonePopup } from './AiTranslationsDonePopup';
import { RemoveLanguagePopup } from './RemoveLanguagePopup';
import { DefaultLanguageSelector } from './DefaultLanguageSelector';

const Translations = () => {
    const {
        state: { selectedRestaurant },
      } = React.useContext(AuthContext);
    const { t } = useTranslation();
    const isRowBased = useMediaQuery("(max-width: 840px)");
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(null);
    const [loadingTranslation, setLoadingTranslation] = useState(false)
    const [segmentsToTranslate, setSegmentsToTranslate] = useState([])
    const [totalSegments, setTotalNumberSegments] = useState(0)
    const [loadingSuggestion, setLoadingSuggestion] = useState(false)
    const [showRemoveTranslationsPopup, setShowRemoveTranslationsPopup] = useState(false)
    const [showAiProgressPopup, setShowAiProgressPopup] = useState(false)
    const [aiProgressPercentage, setAiProgressPercentage] = useState(0)
    const [itemsDone, setItemsDone] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedAvailableLanguagesOptions, setSelectedAvailableLanguagesOptions] = useState([])
    const [model, setModel] = useState(null)
    const [language, setLanguage] = useState(null)
    const [languages, setLanguages] = useState([])
    const [availableLanguages, setAvailableLanguages] = useState([])
    const [defaultLanguage, setDefaultLanguage] = useState(selectedRestaurant[0].default_language ? selectedRestaurant[0].default_language : "spanish")
    const [languagesInColumns, setLanguagesInColumns] = useState()
    const [revisionMode, setRevisionMode] = useState(false)
    const tabs = JSON.parse(localStorage.getItem('tabs'))
    const [translationSaved, setTranslationSaved] = useState(false);
    const [searchText, setSearchText] = useState("");
    const inputRefs = useRef([]);
    const [id, setId] = useState(false)
    const [checkData, setCheckData] = useState(false)
    const [langStartIdx, setLangStartIdx] = useState(0);
    const textAreaRef = useRef([])
    const prevSegmentsToTranslate = useRef([]);

    const history = useHistory();

    const path = history.location.pathname.replace('/translations/', '');
    const tableReference = path.includes('supergroup') ? t('supergroup_name') : path.includes('_group_') ? t('category_group_name') : path.includes('_category') ? t('category') : path.includes('modifier') ? t('Modifier') : t('Product')
    const handleDefaultLanguageChange = async (default_language) => {
        const pk_default_language = default_language['value']
        const data = {"language": pk_default_language, "restaurant": selectedRestaurant[0].value}
        await translation.post(`/default-language`, data).then(async (response) => {
            if (response.status === 200) {
                CustomToast(t("default_language_saved"), "success")
                setDefaultLanguage(pk_default_language)
            } else {
                CustomToast(t("default_language_error"), "error")
            }
        }).catch((e) => {
            CustomToast(t("default_language_error"), "error")
		})
    };

    const handleLanguageSelectorChange = (options) => {
        const selected_options = options.map(op => op.value)
        setSelectedAvailableLanguagesOptions(selected_options)
        const removed_option = availableLanguages.filter(x => !selected_options.includes(x))
        if (removed_option.length > 0) {
            if (removed_option[0] === defaultLanguage) {
                CustomToast(t("not_remove_default_language"), "error")
            } else {
                setShowRemoveTranslationsPopup(true)
            }
        } else {
            handleUpdateAvailableLanguages(selected_options)
        }  
    };

    const handleUpdateAvailableLanguages = async (options) => {
        const data = {"options": options, "restaurant": selectedRestaurant[0].value}
        await translation.post(`/available-languages`, data).then(async (response) => {
            if (response.status === 200) {
                CustomToast(t("available_languages_saved"), "success")
                setAvailableLanguages(options)
                setShowRemoveTranslationsPopup(false)
            } else {
                CustomToast(t("available_languages_error"), "error")
            }
        }).catch((e) => {
            CustomToast(t("available_languages_saved"), "error")
		})
    };
    

    const handleKeyPress = (e, segment, id, language) => {
        if (e.keyCode === 13 || e.which === 13) {
            translationUpdate(e.target.value, segment, id, language)
        }
    };

    const handleTranslationSaved = () => {
        setTranslationSaved(true);
        setTimeout(() => {
            setTranslationSaved(false);
        }, 500);
    };

    const getData = async (endpoint, translationSaved) => {
        if (!loading) {
            let restaurantId = selectedRestaurant[0].value
            endpoint += `?restaurant=${restaurantId}&page=${page}`
            if (searchText) {
                endpoint += `&q=${searchText}`
            }
            await translation.get(endpoint).then(async (response) => {
                if (response.data.ongoing_translation) {
                    translateAiCatalog(response.data.ongoing_translation)
                }
                setRevisionMode(response.data.revision_mode)
                setPage(response.data.page)
                let newSegments = response && response.data.rows
                setSegmentsToTranslate(newSegments)
                setModel(response.data.model)
                const total_segments = response.data.total_rows
                setTotalNumberSegments(total_segments)
                const modulusValue = total_segments % 20;
                const numberOfPages = Math.floor(total_segments / 20) + (modulusValue == 0 ? 0 : 1);
                setNumberOfPages(numberOfPages);
                const all_languages = response.data.languages.map((val) => ({
                    label: t(val),
                    value: val,
                }))
                setLanguages(all_languages)
                setAvailableLanguages(response.data.restaurant_languages)
                let lang_in_col = []
                if (!isRowBased) {
                    lang_in_col = (response.data.restaurant_languages)
                } else {
                    lang_in_col = ([response.data.restaurant_languages[0]])
                }
                setLanguagesInColumns(lang_in_col)
                if (langStartIdx !== 0) {
                    if (langStartIdx + 3 > lang_in_col.length) {
                        setLangStartIdx(lang_in_col.length - 3)
                    }
                }
                setLoading(false)
                translationSaved && handleTranslationSaved()
                prevSegmentsToTranslate.current = newSegments;
            }).catch((e) => {
                setLoading(false)
            })
            setLoading(false)
        }
        
    }

    const translationUpdate = (e, segment, id, language) => {
        setId(id)
        setLanguage(language)
        let newTrans = "new"
        const segment_type = revisionMode ? "revision" : "value"
        if ((e && e.length > 0 && segment[language] &&  segment[language][segment_type]) && (e !== segment[language][segment_type])) {
            newTrans = "edit"
            newTranslation(e, newTrans, language, id)
        } else if (!e && segment[language] && segment[language][segment_type]) {
            newTrans = "delete"
            newTranslation(e, newTrans, language, id)
        } else if((!segment[language] || !segment[language][segment_type]) && e) {
            newTrans = "new"
            newTranslation(e, newTrans, language, id)
        }
        let segmentFound = Object.values(segmentsToTranslate).find((segment) => segment.id === id)
        if (segmentFound[language] == null || !segmentFound[language][segment_type]) {
            segmentFound[language] = {}
        }
        segmentFound[language][segment_type] = e
		setSegmentsToTranslate({...segmentsToTranslate})
    }

    const getSuggestion = async (segment, language, id, is_description) => {
        setLoadingSuggestion(true);
        if (!is_description) {
            setId(segment.id)
            setLanguage(language)
        }
        const e = document.getElementById(id).value
        let newTrans = "new"
        if (e && e.length) {
            newTrans = "edit"
        }
        try {
            const restaurantId = selectedRestaurant[0].value
            const res_obj = await translation.get(`/suggested-translation?text=${segment["default"]}&target=${language}&restaurant=${restaurantId}`);
            const res_data = res_obj.data;
            if (res_data && res_data.suggested_translation){
                const segment_type = revisionMode ? "revision" : "value"
                newTranslation(res_data.suggested_translation, newTrans, language, segment.id)
                const segmentFound = Object.values(segmentsToTranslate).find((segment_to_find) => segment_to_find.id === segment.id)
                if (segmentFound[language] == null || segmentFound[language][segment_type] === null || segmentFound[language][segment_type] === undefined) {
                    segmentFound[language] = {}
                }
                segmentFound[language][segment_type] = res_data.suggested_translation
                setSegmentsToTranslate({...segmentsToTranslate})
            }
        } catch (error) {
        }
        setLoadingSuggestion(false)
    }

    const translateAiCatalog = async (translationId=null) => {
        try {
            let restaurantId = selectedRestaurant[0].value
            setShowAiProgressPopup(true)
            await translateAiCatalogService(restaurantId, setAiProgressPercentage, setItemsDone, setTotalItems, translationId).then(async (response) => {
            }).catch((e) => {
                setShowAiProgressPopup(false)
                setAiProgressPercentage(0)
                CustomToast(t("ai_translation_error"), "error")
            })             
        } catch (error) {
        }
    }

    const confirmRevisionCatalog = async () => {
        try {
            let restaurantId = selectedRestaurant[0].value
            await translation.get(`/confirm-translation-revision?restaurant=${restaurantId}`).then(async (response) => {
                setCheckData(!checkData)
            }).catch((e) => {
                setLoading(false)
            })             
        } catch (error) {
            console.log(error)
        }
    }

    const cancelRevisionMode = async () => {
        try {
            setRevisionMode(false)
            let restaurantId = selectedRestaurant[0].value
            await translation.patch(`/cancel-translation-revision?restaurant=${restaurantId}`).then(async (response) => {
                setCheckData(!checkData);
            }).catch((e) => {
                setLoading(false)
            })             
        } catch (error) {
            console.log(error)
        }
    }

    const changeSearchText = async (value) => {
        setSearchText(value)
        if (value == "") {
            setCheckData(!checkData)
            setPage(1)
        }
    }

    const newTranslation = async (translatedSegment, newTrans, language, id) => {
        try {
            const method = newTrans === 'new' ? 'post' : newTrans === 'edit' ? 'patch' : 'delete'
            const seleccionado = JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) || selectedRestaurant
            let restaurant = null
            if (seleccionado) {
                if (Array.isArray(seleccionado) && seleccionado[0].label !== 'Todos') {
                    restaurant = seleccionado[0]
                } else if (!Array.isArray(seleccionado) && seleccionado && seleccionado.label !== 'Todos') {
                    restaurant = seleccionado
                }
            }
            if (restaurant && !loading) {
                let restaurantId = restaurant.value
                const body = {
                    'model': model,
                    'id': id,
                    'language': language,
                    'value': translatedSegment,
                    'restaurant': restaurantId,
                    'revision': revisionMode
                }
                
                if (((method === 'post' || method === 'patch') || method === 'delete')) {
                    setLoadingTranslation(true);
                    await translation[method]("/translation", method === 'delete' ? { data: body } : body).then(() => {
                        handleTranslationSaved()
                        setLoadingTranslation(false);
                    })
                }
            }

        } catch (e) {
            toast.error(t('an_error_has_occurred'));
            logToServer('error updating translation', selectedRestaurant?.id)
            setLoadingTranslation(false)
        }
    }

    useEffect(() => {
        if(selectedRestaurant && selectedRestaurant.length > 0){
            try {
                const myTab = tabs.find((tab) => tab.name === path)
                setLoading(true)
                getData(`/${myTab.endpoint}`, true)
            }catch(e){
                console.log(e)
            }
        }
    }, [path, selectedRestaurant, page, checkData, availableLanguages])

    


    useEffect(() => {
        const handleClickOutside = (event) => {
            for (let i = 0; i < textAreaRef.current.length; i++) {
                let textAreaRefArray = textAreaRef.current[i]
                if (textAreaRefArray) {
                    for (let j = 0; j < textAreaRefArray.length; j++) {
                        let area = textAreaRef.current[i][j]
                        if (area && !textAreaRefArray.includes(event.target)) {
                            area.style.height = '64px'
                        }
                    }
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const Item = ({ value, disabled = false, index, segment, colLang, pos, type="normal" }) => {
        const handleBlur = (event, index) => {
            if (event.target === textAreaRef.current[pos][index] && segment) {
                translationUpdate(event.target.value, segment, segment.id, colLang)
            }
          };
        const standardClassName = "input input-bordered w-full overflow-ellipsis"
        const classNameByType = {
            "normal": standardClassName + "font-semibold",
            "ai": standardClassName + " bg-[#EBFBF8] font-bold italic text-[#00748F]",
            "manual": standardClassName + " bg-[#F6F2FF] font-bold italic text-[#643FCC]",
        }
        const textAreaStandardClassName = `${isRowBased ? 'text-[14px] ' : 'text-[16px] '} ${disabled && 'text-gray-300 font-semibold text-center'} border-gray-300 h-16 rounded-lg justify-center `
        const textAreaClassNameByType = {
            "normal": textAreaStandardClassName + "font-medium",
            "ai": textAreaStandardClassName + " bg-[#EBFBF8] font-bold italic text-[#00748F]",
            "manual": textAreaStandardClassName + " bg-[#F6F2FF] font-bold italic text-[#643FCC]",
        }


        if (!segment || pos === 0) {
            if (!value) return <span className='text-gray-400'>{t('tpv_value_placeholder')}</span>

            return <div className={`${isRowBased ? 'text-[14px] ' : 'text-[16px] '} h-16 font-semibold mb-2 justify-center flex flex-col truncate`}>
                {value}
            </div>
        }
        return (
            <div className="text-[16px] flex flex-row relative w-11/12">
                {!path.includes('description') ?
                    <input
                        type='text'
                        placeholder={t('write here')}
                        style={{paddingRight: '1.5rem'}}
                        className={classNameByType[type]}
                        defaultValue={value}
                        onBlur={(e) => translationUpdate(e.target.value, segment, segment.id, colLang)}
                        onKeyDown={(e) => handleKeyPress(e, segment, segment.id, colLang)}
                        ref={inputRefs.current[index]}
                        id={`${colLang}-${index}`}
                        disabled={disabled || loadingTranslation || translationSaved || loadingSuggestion}
                    />                
                    :
                    <textarea
                        style={{ margin: 0, width: "100%" }}
                        className={textAreaClassNameByType[type]}
                        defaultValue={value}
                        placeholder={t('write here')}
                        disabled={disabled || loadingTranslation || translationSaved || loadingSuggestion}
                        ref={(el) => {
                            textAreaRef.current[pos] = textAreaRef.current[pos] || [];
                            textAreaRef.current[pos][index] = el
                        }}
                        onBlur={(e) => handleBlur(e,index)}
                        onKeyDown={(e) => handleKeyPress(e, segment, segment.id, colLang)}
                        id={`${colLang}-${index}`}
                    ></textarea>
                }
                {!translationSaved && !loadingTranslation && !loadingSuggestion && colLang &&
                    <div  
                    onClick={() => getSuggestion(segment, colLang, `${colLang}-${index}`, path.includes('description'))}
                    className="absolute right-3 h-auto w-3 cursor-pointer self-center">
                        <Icon 
                            type={IconType.AI} 
                            color='#094553'
                            size={15}
                        />
                    </div>
                    
                }
                {translationSaved && segment && id === segment.id && language === colLang &&
                    <CheckCircleIcon
                        className="absolute right-2 top-2 h-8 w-8"
                    />
                }
                {loadingTranslation && segment && id === segment.id && language === colLang &&
                    <div className="absolute top-8 right-2 transform -translate-y-5">
                        <SpinnerButton />
                    </div>
                }
            </div>
        )
    }
    
    return (
        <div className='bg-gray-100 w-full'>
            <MenuHeader title={t('translations')} route_group='menu' />
            <div className={`flex flex-col gap-7 w-full px-[32px] bg-white py-4 pt-3`}>
                <div className={`flex lg:flex-row gap-5`}>
                    <RestaurantSelect filter={'show_order_and_pay_tab_on_backoffice'} />
                    {!revisionMode ?  
                        <Button
                            label={'translate full catalog'}
                            size={BUTTON_SIZES.SM}
                            onClick={() => translateAiCatalog()}
                            rightIcon={<Icon type={IconType.AI}/>}                
                            className={"self-end"}
                        />   
                    :
                        <div className='flex gap-5 items-end'>
                            <span className='name bg-[#FFF1CC] rounded-[6px] px-2 w-max font-semibold flex items-center h-9'>
                                {t("revision mode")}
                            </span>
                            <Button
                                label={t('confirm and update')}
                                size={BUTTON_SIZES.SM}
                                onClick={() => confirmRevisionCatalog()}
                            />
                            <Button
                                label={t('cancel all changes')}
                                size={BUTTON_SIZES.SM}
                                variant={BUTTON_VARIANTS.SECONDARY}
                                onClick={() => cancelRevisionMode()}
                            />
                        </div>
                    }
                </div>
                <TargetTraductionModelSelector/>     
            </div>
            <div className='filters-container'>   
                <div className='flex gap-8'>          
                    <div className='language-selector-container'>
                        <p>{t("available_languages")}</p>
                        <InfoTooltip info='info_available_languages' position='top'/>
                        <LanguageSelector
                            allLanguages={languages}
                            availableLanguages={availableLanguages}
                            handleChange={handleLanguageSelectorChange}
                            disabled={revisionMode}
                        />
                    </div>
                    <div className='language-selector-container'>
                        <p>{t("default language")}</p>
                        <InfoTooltip info='info_default_language' position='top'/>
                        <DefaultLanguageSelector
                            id={'def_lang_inp'}
                            placeholder={t('Select one option')}
                            options={languages}
                            value={languages.find(ln => ln.value == defaultLanguage)}
                            onChange={handleDefaultLanguageChange}
                            styles={default_language_selector_styles}
                        />
                    </div>
                </div>
                {totalSegments > 0 &&
                <Pagination
                    page={page}
                    numOfPages={numberOfPages}
                    numOfEntries={totalSegments}
                    entryName='items'
                    className='pagination'
                    setPage={setPage}
                />}
            </div>          
            {loading || !segmentsToTranslate || (segmentsToTranslate && JSON.stringify(segmentsToTranslate) !== JSON.stringify(prevSegmentsToTranslate.current)) ?
                <div className={`h-full w-full absolute z-9999 flex flex-col justify-center items-center`}>
                    <Spinner>
                        {t("Loading")}
                    </Spinner>
                </div>
                :
                <>                 
                    {languagesInColumns && 
                        <div className='ml-10 mr-10 mb-10 p-5 bg-white rounded-xl'>
                        <div className='flex justify-between items-center mb-12 relative'>
                            <h1 className='text-xl text-[#094553] font-semibold'>{t(path)}</h1>
                            {revisionMode && 
                                <TableRevisionLegend/>
                            } 
                            <input 
                                key="searchInputKey1"
                                autoFocus
                                placeholder={t('Search')}
                                className="input input-bordered font-medium h-9 " 
                                value={searchText} 
                                onChange={(e) => changeSearchText(e.target.value)}/>
                                <div  
                                onClick={() => setCheckData(!checkData)}
                                className="absolute right-3 h-auto w-3 cursor-pointer self-center">
                                    <SearchIcon height={18}/>
            
                                </div>            
                        </div>
                        {Object.values(segmentsToTranslate).length === 0 ?
                            <div className='w-full flex p-10 flex-row justify-around'>
                                <div className="  m-auto">
                                    {t('no_translations_message')}
                                </div>
                            </div>
                            :
                            <>
                            <div className='header-grid-container' style={{gridTemplateColumns: `.7fr ${path.includes('description') ? "1fr " : ""}.3fr ${availableLanguages.map((l) => "1fr").slice(0,3).join(' ')} .3fr`}}>
                                <p className={`${isRowBased ? 'text-[14px]' : 'text-[16px]'} font-semibold`}>{tableReference}</p>
                                {path.includes('description') && <p style={{textAlign: 'center'}} className={`${isRowBased ? 'text-[14px]' : 'text-[16px]'} font-semibold`}>{t('Description')}</p>}
                                <PaginationButton type="left" disabled={langStartIdx == 0} className="w-100 flex justify-center" onClick={() => setLangStartIdx(langStartIdx - 1)}></PaginationButton>
                                {languagesInColumns.map((colLang, idx) => {
                                            return ((idx >= langStartIdx && idx <= langStartIdx + 2) &&
                                                <div key={idx} className='min-w-max relative'>
                                                    <div className='flex items-center justify-center'>
                                                        <span className={`${isRowBased ? 'text-[14px]' : 'text-[16px]'} font-semibold flex items-center gap-4`}>
                                                            <Icon type={colLang.toUpperCase()}/>
                                                            {t(colLang)}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                })}
                                <PaginationButton type="right" disabled={langStartIdx >= languagesInColumns.length - 3} className="w-100 flex justify-cente" onClick={() => setLangStartIdx(langStartIdx + 1)}></PaginationButton>
                                </div>
                                <div className='content-grid-container' style={{gridTemplateColumns: `.7fr ${path.includes('description') ? "1fr " : ""}.3fr ${availableLanguages.map((l) => "1fr").slice(0,3).join(' ')} .3fr`}}>
                                {path && segmentsToTranslate && Object.values(segmentsToTranslate).map((segment, idx) => {
                                    return (
                                    <>
                                        <Item value={segment.name ? segment.name : segment.default} disabled={true} index={idx} pos={0} />
                                        {path.includes('description') && <div className='flex items-center justify-center'><Item value={segment.default ? segment.default : "-"} segment={segment} disabled={true} index={idx} pos={1} /></div>}
                                        <div></div>
                                        {languagesInColumns.map((item, index) => {
                                            return (
                                            index >= langStartIdx && index <= langStartIdx + 2 &&
                                                <div key={index} className='p-2'>
                                                    <div className='flex items-center justify-center'>
                                                        <Item 
                                                        value={segment[item] ? !revisionMode ? segment[item]["value"] : segment[item]["revision"] : ""} 
                                                        disabled={false} 
                                                        index={idx} 
                                                        segment={segment} 
                                                        colLang={item} 
                                                        pos={index + 2}
                                                        type={segment[item] && revisionMode ? !segment[item]["value"] ? (!segment[item]["revision"] && !segment[item]["ai"]) ? "normal" : segment[item]["revision"] === segment[item]["ai"] ? "ai" : "manual" :  segment[item]["revision"] === segment[item]["value"] ? "normal" : "manual" : "normal"}
                                                        />
                                                    </div>
                                                </div>                                
                                        )})}
                                        <div></div>
                                    </>)
                                })}
                            </div>
                            {totalSegments > 0 &&
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                                    <Pagination
                                        page={page}
                                        numOfPages={numberOfPages}
                                        numOfEntries={totalSegments}
                                        entryName='items'
                                        className='pagination'
                                        setPage={setPage}
                                />
                                </div>}
                            </>
                        }
                    </div>
                    }
                </>}
            <RemoveLanguagePopup
              isOpen={showRemoveTranslationsPopup}
              languageToRemove={availableLanguages.find(x => !selectedAvailableLanguagesOptions.includes(x))}
              cancel={() => setShowRemoveTranslationsPopup(false)}
              confirm={() => handleUpdateAvailableLanguages(selectedAvailableLanguagesOptions)}
            />
            {showAiProgressPopup && aiProgressPercentage < 100 &&
            <AiTranslationProgressPopup
              progressPercentage={aiProgressPercentage}
              itemsDone={itemsDone}
              totalItems={totalItems}
            />}
            {showAiProgressPopup && aiProgressPercentage === 100 &&
            <AiTranslationsDonePopup
              confirm={() => {setShowAiProgressPopup(false); setAiProgressPercentage(0); setRevisionMode(true); setCheckData(!checkData); }}
            />}
        </div>
    );
}

export default Translations;

export const default_language_selector_styles = {
    control: styles => {
      return {
        ...styles,
        height: '40px',
        width: '200px',
        borderRadius: '10px'
      };
    }
  };
  