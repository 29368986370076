import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api } from '../../logic/api';
import { toast } from 'react-toastify';
import { sayError } from 'logic/defaults';
import { TextInput } from 'common/Input';
import { useViewport } from 'data/ViewportContext';
import { Icon, IconType } from 'common/Icon';
import { Logo } from 'common/Logo';
import XTextButton from 'common/XTextButton';

const Update = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams();
  const {isMobile, isTablet} = useViewport();


  React.useEffect(() => {
    if (!token || token === '') {
      history.push('/');
    }
    handleValidateToken();
  }, [history, token]);

  const [submitting, setSubmitting] = React.useState(false);
  const [state, setState] = React.useState({
    password: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidateToken = async () => {
    if (token) {
      try {
        await api.post('/password_reset/validate_token/', { token });
      } catch (error) {
        toast.error(sayError(error.response.data));
        setTimeout(() => history.push('/'), 2000);
      }
    } else {
      toast.error(`${t('no_password')}`);
      setTimeout(() => history.push('/'), 2000);
    }
  };

  const handleUpdate = async () => {
    if (state.password !== '') {
      setSubmitting(true);
      try {
        await api.post('/password_reset/confirm/', {
          password: state.password,
          token,
        });
        toast.success(`${t('update_password_ok')}`);
        setTimeout(() => history.push('/'), 2000);
      } catch (error) {
        toast.error(sayError(error.response.data));
      } finally {
        setSubmitting(false);
      }
    } else {
      toast.error(`${t('no_password')}`);
    }
  };

  return (

    <div className='flex h-screen'>

      {(!isMobile && !isTablet) &&
        <div style={{textAlign: 'center'}} className='flex-1 bg-[#094553] relative justify-center items-center content-center justify-items-center text-center'>
    
          <Logo size={50} className="flex flex-col px-6"/>
    
          <div style={{ width: "100%", height: "auto" }} className='absolute top-0 right-[-50%]'>
            <Icon type={IconType.SEMI_CIRCLE_BOTTOM_PART} />
          </div>
    
          <div style={{ width: "100%", height: "auto" }} className='absolute bottom-0'>
            <Icon type={IconType.SEMI_CIRCLE_TOP_PART_WITH_FILLING} />
          </div>
          
        </div>
      }
  
      <div className='flex-1 bg-white justify-center items-center content-center justify-items-center z-10 relative overflow-hidden'>     
        <div className={'px-2 px-2 w-11/12 lg:max-w-md md:w-4/5'}>
          <h3 style={{fontSize: 'clamp(1.125rem,2vw,1.75rem)'}} className={'font-semibold text-[#094553] py-2'}>
            {t('change_password_text')}
          </h3>

          <div style={{fontSize: 'clamp(1rem,2vw,1.125rem)'}} className={'font-medium text-capitalize py-4 font-semibold'}>
            {t('write_your_new_password')}<span style={{color: 'red'}}>*</span>
          </div>

          <TextInput
            password
            className='mt-2 mb-8'
            name='password'
            placeholder={t('Password')}
            value={state.password || ''}
            onChange={handleChange}
          />

          <XTextButton
            disabled={submitting || state.password === ''}
            submitting={submitting}
            doClick={handleUpdate}
          >
            {isMobile ? t('reset_pass_mobile') : t('reset_password')}
          </XTextButton>

          <Link to='/'>
            <div style={{fontSize: 'clamp(0.875rem,2vw,1rem)', textAlign: 'center'}} className='text-[#094553] underline mt-6 font-semibold'>{t('return_home')}</div>
          </Link>

          <div style={{width: "25%", height: "20%"}} className="absolute bottom-0 right-0 border-[8px] overflow-visible border-none rounded-br-2xl">
            <div style={{borderRadius: "0px 0px 18rem", width: (isMobile || isTablet) ? "195%" : "160%", height: (isMobile || isTablet) ? "15%" : "27%", rotate: (isMobile || isTablet) ? "-238deg" : "-230deg"}} className="bg-[#9DEECB] absolute"></div>
            <div style={{clipPath: "polygon(100% 0, 100% 100%, 0% 100%)", width: (isMobile || isTablet) ? "4rem" : "9rem", height: (isMobile || isTablet) ? "7rem": "11rem"}} className="absolute bottom-0 right-0 bg-[#094553]"></div>
          </div>
        
        </div>
      </div>
    </div>
  );

};

export default Update;
